export const nnJsonNNClient = {
  type: 'NeuralNetwork',
  sizes: [37, 100, 8],
  layers: [
    {
      weights: [],
      biases: [],
    },
    {
      weights: [
        [
          0.07301493734121323, -0.17520949244499207, -0.05454838275909424,
          -0.01129328366369009, 0.1606798768043518, -0.0360751636326313,
          -0.15658049285411835, -0.14226743578910828, 0.035514336079359055,
          0.05620216205716133, -0.003533684415742755, 0.021311111748218536,
          -0.18580585718154907, -0.0974564403295517, -0.010550366714596748,
          -0.10072876513004303, 0.14977909624576569, 0.16631881892681122,
          -0.019729655236005783, -0.19827982783317566, -0.0005957299144938588,
          0.10449476540088654, -0.002262907335534692, -0.19259513914585114,
          0.06315433979034424, -0.032135218381881714, 0.10565625876188278,
          0.09402639418840408, -0.09620673954486847, 0.14173878729343414,
          -0.1671089082956314, -0.06493428349494934, 0.05195359140634537,
          0.05782196298241615, 0.19474798440933228, -0.16343890130519867,
          0.12289224565029144,
        ],
        [
          0.1606796234846115, 0.017472822219133377, -0.10777585953474045,
          0.16708311438560486, -0.15097962319850922, -0.11689987033605576,
          -0.034577902406454086, 0.06807462871074677, -0.07209476083517075,
          -0.11818674951791763, -0.012152071110904217, -0.048108577728271484,
          -0.12580333650112152, -0.022534972056746483, -0.18346086144447327,
          0.024208305403590202, 0.07210259139537811, -0.003172386670485139,
          0.10840144753456116, 0.05628931149840355, -0.1667260229587555,
          -0.09004289656877518, 0.1513129621744156, 0.12568116188049316,
          0.00392113858833909, 0.035639118403196335, 0.11327815055847168,
          0.0728844702243805, -0.17017273604869843, -0.19358837604522705,
          0.05018439143896103, -0.1449146717786789, 0.0626697763800621,
          0.1272103488445282, 0.07927171885967255, -0.03487667068839073,
          0.00020372256403788924,
        ],
        [
          -0.1586647927761078, -0.01494312658905983, 0.04433808475732803,
          -0.18219488859176636, 0.06555204093456268, 0.1221657395362854,
          -0.08961214125156403, -0.10574622452259064, -0.14987342059612274,
          0.09246792644262314, 0.19768503308296204, -0.16679850220680237,
          0.08243515342473984, -0.10937484353780746, 0.1924637258052826,
          0.15170679986476898, -0.0478324294090271, -0.06290663033723831,
          0.1321384161710739, -0.07231427729129791, -0.16794948279857635,
          0.12243881076574326, -0.05842097848653793, -0.10093864053487778,
          -0.08302611112594604, -0.11339972168207169, -0.12999506294727325,
          -0.19087336957454681, 0.14033342897891998, -0.010825415141880512,
          -0.03084738925099373, -0.125645712018013, 0.025333384051918983,
          -0.1259070336818695, 0.1347658932209015, 0.19685252010822296,
          0.15602080523967743,
        ],
        [
          -0.1367170661687851, -0.13237547874450684, -0.14278806746006012,
          0.18381357192993164, -0.12162032723426819, 0.10809348523616791,
          -0.0753602534532547, 0.13587063550949097, -0.021707119420170784,
          -0.10606169700622559, -0.11947005242109299, -0.007367411628365517,
          -0.03467060253024101, -0.1479870080947876, -0.07002439349889755,
          0.08272936940193176, 0.045532602816820145, -0.026602957397699356,
          0.12476879358291626, 0.19912667572498322, -0.1397414356470108,
          0.03600612282752991, 0.13875406980514526, 0.12955056130886078,
          -0.003212457988411188, -0.0017206530319526792, -0.04661589488387108,
          -0.032211240381002426, -0.17747946083545685, 0.04437342286109924,
          -0.027519579976797104, -0.19116848707199097, 0.01153030339628458,
          -0.03901384025812149, -0.1869857907295227, 0.1102137640118599,
          -0.0522172674536705,
        ],
        [
          -0.17092455923557281, 0.11398299038410187, 0.0922904759645462,
          -0.02774268016219139, 0.12674406170845032, 0.1652849018573761,
          -0.1665532886981964, -0.04106594994664192, -0.07831164449453354,
          0.13437257707118988, 0.022146688774228096, -0.056341446936130524,
          -0.14213557541370392, -0.17448273301124573, 0.1349405199289322,
          -0.0020137981045991182, 0.009788770228624344, -0.0017627353081479669,
          0.18867115676403046, 0.17531467974185944, 0.12361810356378555,
          0.12431270629167557, -0.14818315207958221, -0.15320581197738647,
          -0.18900007009506226, -0.055187951773405075, 0.08967311680316925,
          -0.18710583448410034, -0.07146896421909332, -0.17816950380802155,
          0.08936306089162827, 0.11395909637212753, -0.0036214333958923817,
          0.17944414913654327, -0.16288985311985016, -0.053581736981868744,
          -0.1967199295759201,
        ],
        [
          0.02755744382739067, 0.09992605447769165, 0.1990388035774231,
          -0.10904838889837265, -0.153126522898674, 0.18795563280582428,
          -0.11725141108036041, -0.14564701914787292, 0.06198066100478172,
          -0.019772224128246307, -0.18682855367660522, -0.07100298255681992,
          0.11652776598930359, -0.06280296295881271, 0.11603735387325287,
          -0.07009254395961761, 0.022892814129590988, 0.1827506422996521,
          -0.10193638503551483, -0.04775606095790863, 0.03286949545145035,
          0.03394698351621628, -0.08228834718465805, 0.056180715560913086,
          0.21326693892478943, -0.1541568487882614, 0.12405657023191452,
          0.03695588558912277, -0.12595698237419128, 0.020872818306088448,
          0.10578751564025879, 0.19298090040683746, 0.04424399882555008,
          -0.05523106828331947, -0.19616851210594177, -0.17045088112354279,
          0.17020072042942047,
        ],
        [
          -0.17003022134304047, 0.07845498621463776, -0.08097215741872787,
          -0.11614865809679031, -0.14504097402095795, 0.15390990674495697,
          -0.03145043924450874, -0.09692531079053879, -0.04477779567241669,
          -0.09017081558704376, 0.09521786868572235, -0.14454728364944458,
          0.19938106834888458, 0.18649256229400635, 0.16150537133216858,
          0.08140361309051514, 0.06402354687452316, 0.1394399106502533,
          -0.08500604331493378, -0.11102548241615295, -0.1845308542251587,
          0.011270251125097275, -0.03313629701733589, 0.09936193376779556,
          0.01885070465505123, 0.04246210679411888, -0.13187053799629211,
          0.1138463243842125, -0.11580684036016464, -0.17824585735797882,
          -0.04284786805510521, 0.1676161289215088, 0.0851963460445404,
          0.06169882044196129, -0.17079034447669983, 0.08747095614671707,
          0.1481962502002716,
        ],
        [
          0.12024599313735962, -0.036429669708013535, -0.19601446390151978,
          0.078135646879673, -0.17102541029453278, 0.06149934232234955,
          -0.15413959324359894, 0.020786317065358162, -0.03018113411962986,
          -0.12047158181667328, -0.09463884681463242, -0.07220802456140518,
          -0.022823626175522804, 0.06958439946174622, 0.008826393634080887,
          0.16562630236148834, 0.1549408882856369, 0.198541060090065,
          0.1726899892091751, -0.08546050637960434, 0.044659532606601715,
          -0.04276993125677109, 0.16326606273651123, -0.17997220158576965,
          -0.1080680564045906, -0.06903401017189026, -0.16609233617782593,
          0.16122481226921082, -0.16004453599452972, -0.0614287406206131,
          0.06674552708864212, -0.09453622251749039, 0.0034038680605590343,
          -0.046610552817583084, 0.07513556629419327, 0.09984663128852844,
          0.19832353293895721,
        ],
        [
          0.13932311534881592, 0.06647656112909317, 0.19311924278736115,
          0.16289058327674866, 0.19534994661808014, 0.18769848346710205,
          -0.028593480587005615, -0.05663570016622543, 0.17709536850452423,
          -0.19901415705680847, 0.0706184059381485, -0.12983785569667816,
          0.035696886479854584, -0.13893897831439972, -0.022818442434072495,
          0.12274136394262314, 0.08266418427228928, 0.08534733951091766,
          0.1973867416381836, -0.17876382172107697, -0.13474872708320618,
          0.0015325924614444375, 0.14361655712127686, 0.1407049000263214,
          -0.09947092086076736, 0.16690142452716827, -0.09114284813404083,
          -0.05113321542739868, -0.06330305337905884, -0.09878452867269516,
          -0.1631746143102646, -0.04844397306442261, -0.15509149432182312,
          0.026471899822354317, 0.0749153420329094, 0.004986870568245649,
          0.10288636386394501,
        ],
        [
          -0.175530806183815, -0.07395860552787781, 0.02256114035844803,
          -0.1861913949251175, -0.0003769073518924415, -0.11439584940671921,
          -0.11332137137651443, -0.15446723997592926, 0.11433899402618408,
          0.08640924841165543, -0.03276388719677925, -0.05269976332783699,
          0.03692837432026863, 0.1497705578804016, 0.1947803795337677,
          -0.19794230163097382, -0.049293454736471176, -0.027261555194854736,
          0.04582825303077698, 0.06901529431343079, -0.09501027315855026,
          0.09553834050893784, -0.013308605179190636, 0.09185438603162766,
          0.08567436784505844, 0.0030893865041434765, 0.14374196529388428,
          0.07074567675590515, -0.013267886824905872, -0.16603492200374603,
          -0.12535999715328217, 0.0075626191683113575, 0.06301329284906387,
          0.18886718153953552, -0.17390350997447968, -0.14296792447566986,
          -0.11567901819944382,
        ],
        [
          0.13240806758403778, -0.08272702246904373, 0.15607786178588867,
          0.008055055513978004, 0.1865554302930832, 0.0563429594039917,
          0.13617932796478271, 0.1881847232580185, -0.08068817853927612,
          0.13006092607975006, 0.04513860121369362, -0.0371832437813282,
          -0.012584356591105461, -0.1167120710015297, -0.10992429405450821,
          0.00806391704827547, -0.1808900237083435, 0.04049164056777954,
          0.03643128648400307, 0.10211396962404251, 0.05226939544081688,
          0.19594168663024902, -0.017345594242215157, -0.1244482472538948,
          0.17050325870513916, 0.12910862267017365, 0.15287096798419952,
          -0.1344628632068634, 0.17638421058654785, -0.005765849258750677,
          0.05751947686076164, -0.12294450402259827, 0.1141946017742157,
          -0.03331690654158592, -0.08358721435070038, 0.09989709407091141,
          0.05333853140473366,
        ],
        [
          0.06321243196725845, 0.04788115248084068, -0.018653936684131622,
          0.047739177942276, 0.02211320959031582, 0.12111808359622955,
          0.02584008499979973, 0.18902534246444702, -0.18168579041957855,
          -0.13034842908382416, 0.12241216003894806, -0.08163730055093765,
          -0.18731914460659027, 0.11387637257575989, 0.17726609110832214,
          0.04868701845407486, 0.16333192586898804, -0.05429463088512421,
          0.06026545912027359, -0.02166849374771118, 0.07138523459434509,
          0.19128267467021942, -0.1724451780319214, 0.13725724816322327,
          -0.08657527714967728, -0.031032143160700798, -0.03863489255309105,
          0.13816210627555847, -0.07480256259441376, 0.02082553319633007,
          -0.11978204548358917, -0.09149983525276184, -0.1883721500635147,
          -0.03291863948106766, -0.18588225543498993, 0.0826667919754982,
          -0.11438675969839096,
        ],
        [
          -0.11657669395208359, 0.0007593400659970939, 0.12602123618125916,
          -0.08322200924158096, 0.06542735546827316, -0.08782517164945602,
          0.18449144065380096, -0.18973197042942047, -0.03136877343058586,
          0.14550359547138214, 0.19627752900123596, 0.003458544611930847,
          -0.051644474267959595, -0.11845185607671738, -0.15555740892887115,
          0.16783978044986725, 0.14132244884967804, 0.15645281970500946,
          -0.1577882617712021, 0.0925188809633255, 0.1557118147611618,
          0.09925833344459534, -0.07700838148593903, -0.000040021754102781415,
          0.13093529641628265, 0.018366506323218346, 0.1781873255968094,
          -0.09601802378892899, -0.027735555544495583, 0.04618317261338234,
          -0.036050885915756226, 0.016236038878560066, -0.14476777613162994,
          -0.016793709248304367, 0.00030720190261490643, -0.17532245814800262,
          -0.045044150203466415,
        ],
        [
          0.09363628178834915, -0.009798258543014526, 0.16514742374420166,
          0.028509987518191338, -0.16817939281463623, -0.12818489968776703,
          -0.015050841495394707, -0.1049538180232048, -0.03340061753988266,
          -0.06432811170816422, -0.05761173740029335, 0.16549953818321228,
          0.19450385868549347, -0.1886666715145111, -0.018462970852851868,
          -0.08453717082738876, -0.19838842749595642, 0.17342665791511536,
          -0.10219529271125793, 0.044018156826496124, 0.020676150918006897,
          -0.009839537553489208, -0.028857309371232986, -0.041120607405900955,
          -0.08802875876426697, -0.025476543232798576, 0.17512376606464386,
          0.1442900151014328, 0.15548428893089294, -0.05407516285777092,
          0.08454810827970505, -0.058831389993429184, -0.09362589567899704,
          -0.03589027002453804, -0.1709301769733429, 0.06195822358131409,
          -0.11578673124313354,
        ],
        [
          -0.0453321598470211, 0.07063579559326172, -0.18726426362991333,
          -0.14672312140464783, -0.0831020250916481, -0.06963025033473969,
          -0.1622115969657898, -0.014524515718221664, 0.19360242784023285,
          -0.15744613111019135, 0.19993075728416443, 0.12983377277851105,
          0.11074060946702957, 0.007631619926542044, 0.19322660565376282,
          -0.026659458875656128, -0.1303173452615738, 0.051047950983047485,
          -0.13715305924415588, -0.04797081649303436, 0.014799777418375015,
          0.13586819171905518, 0.03436961770057678, -0.02316574938595295,
          0.016156570985913277, -0.0644175261259079, -0.017966298386454582,
          0.06338882446289062, 0.18458878993988037, -0.0960751324892044,
          -0.05427871271967888, 0.15958565473556519, -0.08330100029706955,
          0.12251421809196472, -0.03244895115494728, 0.05172580108046532,
          0.14514800906181335,
        ],
        [
          0.14147725701332092, -0.0952988937497139, -0.11572270095348358,
          -0.10561660677194595, -0.0820886567234993, -0.16479744017124176,
          0.07363261282444, 0.10136935114860535, -0.17520195245742798,
          -0.08719413727521896, -0.18050694465637207, 0.03268228471279144,
          0.0849330946803093, -0.11393892765045166, 0.08930599689483643,
          0.1412116289138794, -0.030026763677597046, -0.18440811336040497,
          -0.04664769768714905, -0.05604056268930435, 0.03011900559067726,
          0.14168767631053925, 0.1686077117919922, 0.09890515357255936,
          -0.18142354488372803, 0.12729832530021667, -0.07546795904636383,
          0.06766820698976517, 0.1191817969083786, -0.1189909353852272,
          0.05808260291814804, -0.05675540119409561, 0.04228648915886879,
          -0.10832838714122772, 0.12356504797935486, 0.15772095322608948,
          -0.14388950169086456,
        ],
        [
          -0.057827625423669815, 0.007688712794333696, 0.05233761668205261,
          -0.003444843227043748, -0.09176340699195862, -0.1847403645515442,
          -0.17850570380687714, -0.0020257194992154837, 0.10614152252674103,
          -0.07834944128990173, 0.14386537671089172, 0.11025602370500565,
          -0.11779994517564774, 0.0011580459540709853, -0.14562256634235382,
          0.1935047209262848, -0.1632019430398941, 0.046424832195043564,
          -0.15548476576805115, 0.025648310780525208, -0.12382867932319641,
          0.21435247361660004, -0.0766415223479271, 0.18711088597774506,
          -0.05904446169734001, -0.12057244032621384, 0.06344038993120193,
          0.050159040838479996, 0.10240072011947632, -0.09525134414434433,
          -0.06080450490117073, 0.1264977604150772, -0.009425494819879532,
          0.07149691879749298, 0.09438471496105194, 0.1485493928194046,
          0.02766917645931244,
        ],
        [
          0.15890252590179443, -0.16699087619781494, -0.05473976209759712,
          0.0421530157327652, 0.18638311326503754, 0.12776677310466766,
          0.11517580598592758, 0.15257491171360016, 0.17838405072689056,
          0.05432514846324921, 0.10552234947681427, 0.150922954082489,
          0.12550769746303558, 0.06757859140634537, -0.018342286348342896,
          0.0018007693579420447, 0.11259172111749649, -0.034642837941646576,
          0.0722360834479332, 0.13064144551753998, 0.12224128097295761,
          0.12251504510641098, 0.15154825150966644, 0.13251033425331116,
          -0.1885855346918106, -0.008030425757169724, 0.013415580615401268,
          0.009123041294515133, 0.07897509634494781, -0.06805406510829926,
          0.10954426974058151, -0.19705279171466827, -0.19188076257705688,
          0.06837555021047592, 0.024163588881492615, 0.1155751645565033,
          -0.14267361164093018,
        ],
        [
          0.03732066601514816, 0.11706624925136566, 0.0003374401421751827,
          -0.09238369762897491, 0.08712422102689743, -0.1764295995235443,
          0.14534658193588257, -0.11343330144882202, -0.1816137433052063,
          0.06619635224342346, -0.0713210478425026, 0.19930709898471832,
          -0.1641751527786255, 0.14486192166805267, -0.010687574744224548,
          -0.08590617775917053, -0.06623297929763794, -0.18053115904331207,
          0.11725675314664841, -0.10757885128259659, -0.0013370998203754425,
          -0.06637915968894958, 0.10018761456012726, 0.1162433922290802,
          0.10159946233034134, 0.07458911091089249, 0.012472991831600666,
          -0.14949533343315125, 0.06423358619213104, 0.13572072982788086,
          0.19916440546512604, -0.007343463599681854, -0.09486348181962967,
          -0.08615098893642426, -0.15419459342956543, 0.16340728104114532,
          0.13763698935508728,
        ],
        [
          0.16180439293384552, -0.1793542355298996, -0.02143821492791176,
          0.1113898977637291, 0.09326301515102386, -0.13576556742191315,
          -0.13570702075958252, 0.14991706609725952, 0.09072522073984146,
          0.11188050359487534, 0.038454845547676086, -0.18534506857395172,
          0.1350734978914261, -0.16093118488788605, 0.014756457880139351,
          0.08244509249925613, -0.10278674960136414, 0.17982268333435059,
          -0.058790963143110275, 0.06576864421367645, -0.028500543907284737,
          0.06405148655176163, 0.13555996119976044, -0.1774178147315979,
          0.019986912608146667, -0.14374545216560364, 0.0041932761669158936,
          -0.1147308349609375, -0.027171263471245766, -0.030628930777311325,
          -0.11332564800977707, 0.19482477009296417, -0.16098226606845856,
          0.19873952865600586, 0.09123189002275467, -0.1062152311205864,
          0.12603189051151276,
        ],
        [
          0.17714335024356842, 0.12766580283641815, 0.04280524328351021,
          0.05080612748861313, -0.004019320011138916, -0.11864843219518661,
          0.16183659434318542, 0.06683211773633957, -0.1922357827425003,
          -0.08510029315948486, -0.07491375505924225, 0.16679707169532776,
          0.15531013906002045, -0.0940367579460144, 0.10337342321872711,
          0.06694217771291733, -0.13373471796512604, 0.09173522889614105,
          -0.10802673548460007, 0.029638981446623802, -0.019187072291970253,
          -0.10474114120006561, -0.1664777547121048, 0.19484996795654297,
          -0.027949487790465355, 0.17751863598823547, -0.01838172972202301,
          -0.15833787620067596, 0.07869129627943039, -0.036926042288541794,
          0.10884997248649597, 0.0506560318171978, 0.0993867740035057,
          -0.18926462531089783, -0.028162360191345215, 0.188812717795372,
          0.0542445182800293,
        ],
        [
          -0.09778916835784912, -0.1672021448612213, -0.15961582958698273,
          -0.011508622206747532, 0.18937882781028748, 0.09426026791334152,
          -0.0777423083782196, 0.06384450197219849, 0.11879896372556686,
          0.1176900565624237, -0.1963665783405304, 0.04368332773447037,
          0.056156259030103683, -0.012242588214576244, 0.08837302774190903,
          -0.0728268101811409, -0.04156377539038658, -0.18757282197475433,
          0.0923178642988205, -0.005407038610428572, -0.1509452760219574,
          -0.0727776512503624, 0.011637580581009388, 0.07346244156360626,
          -0.0011025751009583473, -0.1629503220319748, 0.10614228248596191,
          0.07664825767278671, -0.05856969952583313, 0.08205731213092804,
          0.07729527354240417, 0.028473185375332832, 0.0711425319314003,
          0.06994235515594482, 0.13385415077209473, -0.16180455684661865,
          -0.09029699862003326,
        ],
        [
          -0.04324214532971382, 0.12943010032176971, -0.03555311635136604,
          -0.09225714206695557, 0.18340513110160828, -0.03414527326822281,
          0.1991153210401535, -0.18979957699775696, -0.08156290650367737,
          0.07602856308221817, -0.09738881140947342, -0.1414167433977127,
          0.12216392904520035, -0.018521707504987717, 0.13247540593147278,
          0.05205470696091652, 0.050020281225442886, 0.1450609266757965,
          -0.12630976736545563, 0.15755468606948853, -0.006671755108982325,
          -0.12031251937150955, -0.1837073266506195, 0.1420750766992569,
          0.15859442949295044, 0.10777629166841507, 0.0077232010662555695,
          0.10677876323461533, -0.19887347519397736, 0.02671566791832447,
          -0.11830446124076843, 0.12488184124231339, 0.08876501023769379,
          -0.08088775724172592, 0.12688489258289337, -0.1969422996044159,
          0.11817892640829086,
        ],
        [
          -0.1004393920302391, 0.035559386014938354, -0.1261194348335266,
          0.1682375818490982, -0.10113577544689178, -0.04548213258385658,
          0.06991881132125854, 0.07630246877670288, -0.058674100786447525,
          -0.04365234076976776, 0.05393184348940849, 0.0832628533244133,
          0.1459813416004181, 0.015283298678696156, -0.13537590205669403,
          -0.15780076384544373, 0.14706125855445862, 0.1265704482793808,
          0.06693850457668304, 0.11693350225687027, 0.0036318751517683268,
          -0.10751262307167053, -0.08946727961301804, 0.09465643018484116,
          0.1338105946779251, -0.09748069941997528, -0.10650254040956497,
          -0.13939017057418823, -0.010607481934130192, 0.06406055390834808,
          -0.100661501288414, -0.026471473276615143, 0.1700224131345749,
          -0.011741363443434238, 0.14219114184379578, 0.09262461960315704,
          -0.15661606192588806,
        ],
        [
          0.10370901226997375, -0.11056338250637054, -0.17632152140140533,
          0.15271329879760742, -0.04432116076350212, -0.038225747644901276,
          0.05563441291451454, -0.0707874670624733, -0.07773987948894501,
          -0.05035156384110451, -0.051815006881952286, 0.0066208369098603725,
          0.11605106294155121, -0.18566206097602844, -0.13686254620552063,
          0.025973044335842133, -0.06894578039646149, -0.04267992824316025,
          -0.07982781529426575, 0.05288197100162506, 0.08522232621908188,
          0.027707727625966072, -0.026169154793024063, 0.12535791099071503,
          -0.03061368316411972, -0.2200639396905899, -0.0663377195596695,
          0.07795584201812744, -0.03205448389053345, -0.08250956982374191,
          0.09826568514108658, 0.08338436484336853, 0.002597508719190955,
          -0.17812839150428772, -0.07811032235622406, -0.006900927517563105,
          -0.019116483628749847,
        ],
        [
          -0.11936324834823608, 0.11463053524494171, -0.07144643366336823,
          0.06926227360963821, 0.058529216796159744, 0.09289204329252243,
          0.05692535266280174, 0.1951795220375061, 0.04608422517776489,
          0.08344680070877075, -0.18483704328536987, 0.17969614267349243,
          -0.0982370525598526, -0.044828999787569046, -0.1855689287185669,
          -0.1500239074230194, -0.16588331758975983, 0.1346605271100998,
          0.10887666046619415, -0.15433470904827118, -0.1478234976530075,
          0.011192936450242996, 0.00009865126776276156, 0.03569057583808899,
          0.1783449500799179, -0.0755162239074707, -0.022106336429715157,
          0.12247002124786377, 0.10595395416021347, 0.02918638475239277,
          0.04819325730204582, -0.1988024115562439, 0.061723604798316956,
          -0.1463727205991745, 0.12348195165395737, 0.019347630441188812,
          -0.03877004608511925,
        ],
        [
          -0.0051146564073860645, -0.18217487633228302, -0.08056394010782242,
          -0.06206236779689789, 0.0475040003657341, 0.030984777957201004,
          -0.053036030381917953, -0.0930446982383728, 0.09813908487558365,
          -0.08469496667385101, 0.015079887583851814, -0.016320981085300446,
          -0.0878494530916214, -0.18037186563014984, 0.18138206005096436,
          -0.08428025990724564, 0.00002003809277084656, 0.08413321524858475,
          0.04789866507053375, 0.04021601378917694, 0.14080166816711426,
          0.050550032407045364, 0.01822979934513569, -0.020125048235058784,
          -0.20145756006240845, 0.08936141431331635, 0.013641628436744213,
          -0.062169335782527924, 0.13437077403068542, 0.07215793430805206,
          -0.10031317919492722, 0.0899207592010498, 0.12413901835680008,
          0.08511801064014435, 0.18066057562828064, -0.00267553748562932,
          -0.13251277804374695,
        ],
        [
          -0.09880854189395905, 0.07229246199131012, 0.09435849636793137,
          0.051425863057374954, 0.06783317029476166, -0.051983729004859924,
          -0.1518222838640213, 0.1496017575263977, 0.08761750161647797,
          -0.13968883454799652, 0.15253908932209015, -0.13912655413150787,
          -0.015218019485473633, 0.0325828455388546, -0.07287726551294327,
          -0.18217508494853973, 0.16895295679569244, -0.18192318081855774,
          0.05276838690042496, -0.12318664789199829, -0.10229377448558807,
          0.1557988077402115, 0.16191820800304413, -0.15938179194927216,
          0.13808204233646393, 0.14620862901210785, -0.12104777246713638,
          0.1838068813085556, -0.18548375368118286, -0.04946059361100197,
          0.06672557443380356, -0.00015719770453870296, 0.16069838404655457,
          -0.13117440044879913, -0.0848851203918457, -0.15112560987472534,
          -0.08405712991952896,
        ],
        [
          -0.15414123237133026, 0.034248340874910355, -0.07707829028367996,
          0.01676352135837078, -0.05858324095606804, -0.005450011231005192,
          -0.011264581233263016, -0.1310197114944458, 0.07365164905786514,
          0.1635168045759201, -0.18465718626976013, -0.003050212748348713,
          -0.025710050016641617, 0.004885184112936258, 0.0815012976527214,
          -0.016173893585801125, -0.16679857671260834, -0.1479298621416092,
          -0.08869972079992294, -0.1800253540277481, -0.11969500780105591,
          -0.0846179723739624, 0.20264562964439392, -0.07369448244571686,
          -0.06184956058859825, 0.00879061408340931, 0.16188031435012817,
          -0.15108822286128998, -0.17303307354450226, -0.055188246071338654,
          -0.08996988087892532, 0.11250617355108261, -0.09861721098423004,
          0.03613691404461861, -0.13700394332408905, 0.13483303785324097,
          0.19931961596012115,
        ],
        [
          -0.002179669914767146, 0.09295383840799332, 0.06733173131942749,
          -0.06910517811775208, 0.17914161086082458, 0.01974920928478241,
          0.18811190128326416, -0.14958952367305756, -0.14308907091617584,
          -0.13141390681266785, 0.16363437473773956, 0.11752938479185104,
          -0.1836095154285431, -0.18208959698677063, 0.12183623760938644,
          -0.1573236733675003, 0.16642922163009644, 0.02898460067808628,
          -0.1796911507844925, -0.1815645545721054, -0.10683681070804596,
          0.07039926201105118, 0.19618253409862518, -0.09578303247690201,
          0.17519065737724304, 0.15464425086975098, -0.11862006038427353,
          0.13978300988674164, -0.16179928183555603, -0.039977066218853,
          0.1058349460363388, -0.14575721323490143, 0.030644778162240982,
          0.08504202961921692, -0.008846096694469452, -0.1570015549659729,
          0.08087951689958572,
        ],
        [
          0.049753446131944656, 0.05232441797852516, 0.019954176619648933,
          0.024944065138697624, -0.19493168592453003, 0.16720256209373474,
          0.18320336937904358, -0.18061652779579163, 0.1404697597026825,
          0.13381358981132507, -0.13976503908634186, -0.19024384021759033,
          0.0788392424583435, -0.027666913345456123, -0.04392571747303009,
          -0.029395170509815216, 0.17676804959774017, 0.06657286733388901,
          -0.01656237430870533, -0.06245962157845497, 0.17761173844337463,
          0.02123877964913845, -0.1860615760087967, -0.08070266246795654,
          -0.06810919940471649, 0.0783027932047844, -0.10019505769014359,
          0.12963978946208954, 0.14211556315422058, 0.14396242797374725,
          -0.13463009893894196, 0.1624058485031128, -0.11514275521039963,
          -0.17758797109127045, -0.0050275810062885284, -0.18577685952186584,
          -0.06908566504716873,
        ],
        [
          0.10394880920648575, -0.008213857188820839, -0.17631006240844727,
          0.11281605064868927, -0.13501207530498505, -0.04642810672521591,
          0.00434223422780633, -0.018439391627907753, -0.08062534779310226,
          -0.1489991545677185, 0.1053389385342598, 0.12015307694673538,
          0.15799321234226227, -0.03888087347149849, 0.11653292179107666,
          0.16791695356369019, -0.16344612836837769, 0.15377412736415863,
          0.10356159508228302, 0.11924683302640915, -0.05536743625998497,
          0.09338433295488358, -0.13037855923175812, 0.03825991973280907,
          -0.09937600046396255, 0.15653088688850403, 0.0011544576846063137,
          -0.02608625404536724, 0.016840364784002304, 0.025332795456051826,
          0.017511265352368355, -0.07171984761953354, -0.10926095396280289,
          -0.15947723388671875, 0.10968036204576492, 0.011816580779850483,
          -0.0712423101067543,
        ],
        [
          -0.08604508638381958, -0.18261684477329254, -0.10420946031808853,
          0.18406689167022705, 0.08219600468873978, 0.1524098962545395,
          0.18600577116012573, 0.14329110085964203, 0.07821063697338104,
          -0.08838700503110886, 0.08647598326206207, 0.07773859053850174,
          -0.15599556267261505, -0.1387413889169693, -0.1992514431476593,
          -0.14896471798419952, -0.11055920273065567, -0.11288630962371826,
          -0.11764565855264664, -0.18668857216835022, 0.009188789874315262,
          0.13543307781219482, -0.1274486482143402, -0.0182250514626503,
          -0.10989990830421448, -0.009760428220033646, -0.10389634221792221,
          0.15472570061683655, -0.10082900524139404, -0.0841996818780899,
          0.15734471380710602, 0.10746895521879196, -0.04521017149090767,
          -0.13519258797168732, -0.01462596096098423, 0.02829109877347946,
          -0.16998648643493652,
        ],
        [
          -0.09246362000703812, -0.07481817901134491, -0.10739316791296005,
          0.11841544508934021, 0.03950722888112068, -0.09973278641700745,
          -0.08939474821090698, -0.08432134985923767, -0.1859920471906662,
          0.1821102648973465, -0.0910230427980423, 0.18681904673576355,
          -0.007817261852324009, -0.1745041459798813, -0.1273198425769806,
          -0.0526936911046505, -0.1337536722421646, 0.015543696470558643,
          -0.08444742858409882, -0.03525291383266449, 0.06684678047895432,
          0.07682333141565323, -0.20395627617835999, -0.07137708365917206,
          0.052245382219552994, 0.14272059500217438, 0.09956133365631104,
          0.037124354392290115, -0.07105586677789688, 0.07590379565954208,
          -0.11233371496200562, 0.19732242822647095, 0.17079311609268188,
          -0.04885763302445412, 0.12788434326648712, 0.09710440039634705,
          0.027172023430466652,
        ],
        [
          -0.12730365991592407, -0.025312289595603943, -0.11235396564006805,
          -0.16065821051597595, -0.09844659268856049, -0.16761192679405212,
          -0.05795246362686157, -0.06621306389570236, 0.1211848184466362,
          -0.16348783671855927, -0.020474672317504883, 0.00499686598777771,
          0.042274706065654755, 0.12622204422950745, 0.16913217306137085,
          0.19811579585075378, 0.03648892045021057, -0.1845550686120987,
          -0.15642960369586945, -0.026488926261663437, 0.17735429108142853,
          -0.18998390436172485, -0.09300567954778671, 0.0847046747803688,
          0.0015213722363114357, 0.011609969660639763, -0.07313396036624908,
          0.17192240059375763, -0.188485249876976, 0.1564350128173828,
          -0.11224760860204697, 0.1681809276342392, 0.15838868916034698,
          -0.07273370772600174, -0.09413830935955048, 0.10541342943906784,
          0.10219524800777435,
        ],
        [
          -0.08363375812768936, 0.10997483134269714, -0.1217532604932785,
          0.14759351313114166, 0.08299116790294647, -0.0360654853284359,
          0.18116629123687744, -0.04664229601621628, 0.15031924843788147,
          0.0873747393488884, -0.04936274513602257, -0.15801702439785004,
          -0.1501947045326233, 0.19134943187236786, -0.14088919758796692,
          -0.18810029327869415, -0.026880702003836632, 0.05315353348851204,
          0.15720826387405396, -0.13793359696865082, 0.007181934546679258,
          -0.07740361988544464, 0.14717896282672882, 0.11579620093107224,
          0.03740798309445381, -0.1871219277381897, 0.09827014803886414,
          -0.07056321203708649, 0.13845786452293396, -0.0104222996160388,
          -0.12074770033359528, 0.05919097736477852, -0.15010276436805725,
          0.10157085955142975, -0.1818765252828598, 0.15665856003761292,
          -0.07909487932920456,
        ],
        [
          0.03581245616078377, -0.07495085895061493, 0.0583476722240448,
          0.13823653757572174, -0.1960505247116089, 0.09803010523319244,
          0.022596348077058792, -0.08681657165288925, -0.033765386790037155,
          0.19820015132427216, 0.13867662847042084, -0.010260457172989845,
          -0.1418309062719345, -0.17418327927589417, -0.07151152193546295,
          0.013968374580144882, 0.08760897815227509, 0.10293065756559372,
          -0.08432356268167496, 0.12965402007102966, 0.13743895292282104,
          -0.1030583307147026, -0.011849152855575085, -0.1334419697523117,
          -0.026227964088320732, 0.062241699546575546, -0.025160307064652443,
          -0.18501432240009308, -0.07791534066200256, 0.03920269012451172,
          0.09874538332223892, 0.01889788545668125, -0.03872421756386757,
          0.09864901006221771, -0.002175229834392667, -0.13350871205329895,
          0.08830170333385468,
        ],
        [
          0.008451445028185844, -0.17388901114463806, 0.09559008479118347,
          -0.011269651353359222, -0.17868615686893463, 0.17076268792152405,
          -0.08716612309217453, 0.12951907515525818, 0.06540383398532867,
          -0.04626055434346199, -0.11911001056432724, -0.18026456236839294,
          -0.09800807386636734, -0.11961691081523895, 0.0526849739253521,
          0.05921824648976326, 0.10290922969579697, -0.16873280704021454,
          -0.1732388585805893, 0.08281747996807098, -0.1562233865261078,
          -0.04262484237551689, 0.03220511972904205, 0.10236337035894394,
          0.048141103237867355, 0.15248262882232666, -0.08892827481031418,
          -0.10064273327589035, 0.11044825613498688, -0.08366122841835022,
          0.014674155041575432, 0.03283446654677391, -0.08166687190532684,
          -0.13650953769683838, -0.03319348394870758, -0.08529843389987946,
          0.15125921368598938,
        ],
        [
          0.08926155418157578, 0.009951060637831688, -0.0335061140358448,
          0.03600757569074631, 0.13830573856830597, -0.13215436041355133,
          -0.010078366845846176, 0.15271495282649994, -0.19755350053310394,
          -0.09574298560619354, 0.18422003090381622, 0.15684586763381958,
          -0.0415424145758152, 0.15650343894958496, -0.18400821089744568,
          0.12121962755918503, 0.0451735183596611, 0.1350957155227661,
          -0.06905312836170197, -0.06501876562833786, -0.17957790195941925,
          0.026793131604790688, -0.19071969389915466, -0.02304275892674923,
          0.03475312516093254, 0.14261096715927124, -0.1632099449634552,
          0.193088099360466, -0.13072064518928528, 0.02034684270620346,
          -0.059419695287942886, 0.07652289420366287, -0.1714949905872345,
          -0.1309318095445633, 0.04923473671078682, -0.01005598809570074,
          -0.029766764491796494,
        ],
        [
          0.16441506147384644, -0.11973845213651657, -0.19170783460140228,
          -0.010539152659475803, 0.023542754352092743, -0.1931304633617401,
          0.03724120557308197, 0.009065349586308002, -0.0948687493801117,
          0.08745904266834259, 0.1610535830259323, -0.08606738597154617,
          0.12801706790924072, -0.09934256225824356, -0.15903890132904053,
          0.039699818938970566, 0.08894988149404526, -0.05911779776215553,
          -0.0529739074409008, 0.1079271212220192, -0.11178018152713776,
          0.08918873220682144, 0.05124082416296005, 0.025840923190116882,
          -0.0707939863204956, -0.1089528501033783, -0.12372799962759018,
          -0.1170758455991745, -0.15350455045700073, 0.17452292144298553,
          -0.09678581357002258, 0.04553750529885292, -0.14929354190826416,
          0.15350113809108734, 0.04416239261627197, 0.056358739733695984,
          -0.1181599497795105,
        ],
        [
          -0.020818354561924934, -0.13839676976203918, -0.04146677628159523,
          -0.006259542889893055, -0.08150801807641983, -0.12450016289949417,
          -0.11397808790206909, -0.11184395849704742, 0.13365308940410614,
          0.09977448731660843, 0.19295498728752136, -0.08940429240465164,
          -0.021932505071163177, 0.06296571344137192, 0.15532734990119934,
          -0.13739433884620667, -0.069260373711586, -0.000732655287720263,
          0.03257783502340317, -0.03201437368988991, -0.024712489917874336,
          0.22386063635349274, 0.021708743646740913, -0.20483878254890442,
          0.16252829134464264, 0.06864603608846664, -0.1274658441543579,
          0.09380294382572174, 0.08255176991224289, 0.08299484848976135,
          0.16909034550189972, 0.14775313436985016, -0.0743427500128746,
          -0.1553146243095398, 0.0030952191445976496, -0.17309655249118805,
          -0.06544490903615952,
        ],
        [
          0.13128168880939484, -0.018688252195715904, -0.13667652010917664,
          -0.004892231430858374, 0.038524236530065536, 0.05636042356491089,
          0.09118322283029556, 0.030627775937318802, 0.10077051818370819,
          0.06755443662405014, 0.14949344098567963, 0.12330838292837143,
          0.05571490526199341, -0.016097817569971085, -0.012727177701890469,
          0.00944097712635994, -0.05047713965177536, -0.1396041363477707,
          0.05315442010760307, 0.1460285186767578, -0.1449330598115921,
          0.04006968066096306, 0.07085110247135162, -0.017482619732618332,
          0.03785533830523491, 0.18303148448467255, 0.00834312941879034,
          0.12438403069972992, -0.018909495323896408, 0.19251447916030884,
          0.06622069329023361, 0.19402265548706055, -0.019218122586607933,
          -0.13847894966602325, -0.036441490054130554, 0.039941877126693726,
          0.14320676028728485,
        ],
        [
          0.08045262098312378, -0.02077130787074566, 0.17012996971607208,
          0.028773006051778793, -0.0823654904961586, -0.1843985915184021,
          0.13035927712917328, -0.16290172934532166, 0.007665764074772596,
          0.12173561751842499, -0.10431544482707977, 0.15405790507793427,
          0.07359933108091354, -0.02816484495997429, -0.0912291407585144,
          -0.049131155014038086, -0.022351296618580818, -0.17506569623947144,
          0.1531277596950531, 0.09664992988109589, -0.026233237236738205,
          0.09628048539161682, -0.026510514318943024, -0.02994639240205288,
          0.09039002656936646, 0.025906486436724663, -0.018792055547237396,
          -0.1396581530570984, -0.049656715244054794, -0.10288335382938385,
          0.18821343779563904, 0.16135726869106293, -0.12977814674377441,
          -0.10855370759963989, -0.08541782945394516, 0.056055378168821335,
          0.17828381061553955,
        ],
        [
          0.17536962032318115, 0.007519556209445, -0.15580013394355774,
          -0.15945854783058167, -0.1193985715508461, -0.17727629840373993,
          0.07841336727142334, 0.0894293487071991, -0.18975910544395447,
          -0.13645070791244507, 0.11330772936344147, -0.15537536144256592,
          -0.07312414795160294, -0.0011128077749162912, 0.013318188488483429,
          0.10221950709819794, 0.004600336775183678, 0.1789342612028122,
          0.05010874569416046, -0.056222572922706604, -0.06985108554363251,
          0.12832479178905487, 0.0451902337372303, 0.1009921133518219,
          0.050147853791713715, -0.05637168139219284, -0.08986519277095795,
          0.1722513884305954, -0.18377701938152313, -0.1097184345126152,
          -0.001669848570600152, 0.1969996839761734, 0.10581261664628983,
          0.1078852191567421, 0.10195781290531158, -0.19445832073688507,
          0.18272186815738678,
        ],
        [
          0.07787327468395233, -0.11891062557697296, 0.12770751118659973,
          -0.17000730335712433, 0.04401461407542229, -0.1703917235136032,
          0.07429797947406769, 0.1450960487127304, -0.014965294860303402,
          -0.08447199314832687, -0.06432075053453445, -0.010231241583824158,
          0.07927916198968887, 0.08716131001710892, 0.07805150747299194,
          0.05840234085917473, -0.17161241173744202, 0.08216775953769684,
          -0.12103322148323059, 0.1239091083407402, 0.10189518332481384,
          -0.026868300512433052, -0.022210661321878433, 0.03307872265577316,
          -0.04516233503818512, 0.08625750243663788, -0.07454068958759308,
          0.03787580505013466, 0.09462817013263702, 0.2041621059179306,
          -0.08490610122680664, 0.08646169304847717, 0.09131014347076416,
          -0.049061842262744904, -0.15739008784294128, 0.1507677584886551,
          -0.10847502201795578,
        ],
        [
          0.06874698400497437, -0.19382645189762115, -0.07220860570669174,
          -0.18753139674663544, -0.02263195626437664, -0.08132459223270416,
          -0.012759272940456867, -0.05411287024617195, -0.14606693387031555,
          0.16296137869358063, -0.1134190484881401, 0.012420440092682838,
          -0.08267737179994583, -0.07095199078321457, 0.037305235862731934,
          -0.16350029408931732, 0.14062286913394928, -0.009575090371072292,
          0.004425026010721922, 0.14471124112606049, 0.02858850732445717,
          0.12489823251962662, 0.1684512197971344, 0.037222474813461304,
          -0.09660907089710236, -0.11814194172620773, 0.15149350464344025,
          -0.09416335076093674, -0.03119262494146824, 0.14679117500782013,
          -0.10422734916210175, -0.16881990432739258, 0.02077310159802437,
          -0.17741258442401886, 0.18526436388492584, -0.12613467872142792,
          -0.014109975658357143,
        ],
        [
          -0.028458885848522186, -0.11736978590488434, 0.1699996143579483,
          -0.19130907952785492, 0.19299952685832977, 0.10105732083320618,
          -0.03178325667977333, -0.10853755474090576, 0.0490998700261116,
          -0.17694148421287537, -0.04606055095791817, 0.1594998985528946,
          -0.021217461675405502, 0.05272446200251579, 0.18620112538337708,
          -0.19138477742671967, 0.18287283182144165, -0.13581404089927673,
          -0.12849102914333344, -0.1651620715856552, 0.05093579366803169,
          -0.0675208792090416, -0.007010845933109522, -0.11238133162260056,
          -0.19908465445041656, -0.024967273697257042, -0.03239653632044792,
          -0.15786097943782806, -0.10955886542797089, -0.11848186701536179,
          0.022305447608232498, -0.135518416762352, -0.10170616209506989,
          -0.10835617035627365, 0.09723612666130066, 0.15387973189353943,
          0.18569976091384888,
        ],
        [
          -0.0023985907901078463, -0.020772887393832207, 0.09926682710647583,
          0.11291681230068207, 0.15714776515960693, -0.07382509112358093,
          -0.10513991862535477, -0.1204235851764679, 0.11648637056350708,
          -0.15731360018253326, -0.061925098299980164, 0.12093586474657059,
          0.00713381776586175, 0.01657039113342762, -0.09138211607933044,
          -0.023310532793402672, -0.09004073590040207, -0.06745114922523499,
          -0.09672769904136658, 0.13997647166252136, -0.2226775884628296,
          -0.01770348846912384, 0.17851009964942932, 0.010778811760246754,
          0.004763161763548851, 0.10940885543823242, 0.03877228870987892,
          0.06473373621702194, -0.1563899666070938, 0.17318199574947357,
          0.017262274399399757, 0.17661476135253906, 0.08279972523450851,
          -0.1982530653476715, -0.08858008682727814, 0.1514507681131363,
          0.16116830706596375,
        ],
        [
          -0.02512321248650551, -0.10131622850894928, 0.12917152047157288,
          -0.15325704216957092, 0.182361900806427, -0.09757858514785767,
          0.13702823221683502, -0.0233048964291811, 0.14106851816177368,
          -0.005792065057903528, 0.11760497838258743, 0.05818372219800949,
          -0.07465144246816635, -0.10285419225692749, 0.06587394326925278,
          0.17905683815479279, -0.015467766672372818, 0.02340538799762726,
          0.11625824123620987, 0.12394733726978302, 0.18672578036785126,
          -0.056469131261110306, -0.14720693230628967, -0.056787118315696716,
          -0.0612354539334774, 0.15575644373893738, -0.059474486857652664,
          0.08751514554023743, -0.12273009121417999, -0.07268855720758438,
          -0.17353986203670502, -0.15655037760734558, -0.1647900491952896,
          -0.14039596915245056, 0.10486730933189392, 0.15317955613136292,
          -0.08426829427480698,
        ],
        [
          -0.040230315178632736, -0.023938972502946854, -0.0917084589600563,
          0.09699152410030365, -0.19208863377571106, 0.0017123912693932652,
          -0.15619969367980957, 0.18130402266979218, 0.19200149178504944,
          0.06615931540727615, 0.10587558150291443, 0.13475708663463593,
          0.1229102686047554, -0.10777313262224197, 0.0010818943846970797,
          -0.1847669631242752, -0.04283734783530235, 0.16789278388023376,
          -0.16658148169517517, 0.09499669820070267, -0.13460980355739594,
          -0.16488640010356903, -0.07701339572668076, -0.05653418228030205,
          -0.05129915103316307, -0.021037675440311432, -0.10994857549667358,
          0.13881313800811768, 0.14026425778865814, 0.08470813184976578,
          -0.12348957359790802, 0.08197202533483505, 0.11560618877410889,
          0.02640598453581333, -0.16341757774353027, 0.051317982375621796,
          -0.006244157440960407,
        ],
        [
          0.033631373196840286, 0.16894832253456116, 0.07810475677251816,
          -0.14235182106494904, -0.16054365038871765, 0.1942743957042694,
          0.0881006047129631, 0.027144670486450195, -0.043304700404405594,
          0.15133602917194366, 0.17068055272102356, -0.034561432898044586,
          -0.16290898621082306, 0.009104673750698566, 0.053793761879205704,
          0.11227700859308243, -0.030070340260863304, 0.0743701383471489,
          0.0363435372710228, 0.1207984909415245, 0.10592835396528244,
          0.2350507527589798, 0.06418140977621078, -0.1857430636882782,
          0.02948002703487873, 0.13668064773082733, -0.15015803277492523,
          0.04476271569728851, 0.08504233509302139, 0.13320773839950562,
          0.11509130150079727, -0.15366685390472412, 0.13063755631446838,
          0.15008363127708435, 0.09203218668699265, 0.09462498873472214,
          0.04928193986415863,
        ],
        [
          -0.14734651148319244, -0.1236029863357544, 0.07229036837816238,
          -0.17406858503818512, -0.1984555572271347, -0.15193606913089752,
          0.09975811094045639, -0.08361509442329407, -0.13907282054424286,
          0.07887135446071625, 0.07439639419317245, 0.05886704847216606,
          0.07199501246213913, 0.08137784153223038, 0.1235751062631607,
          0.1216786727309227, -0.06885940581560135, -0.1698806881904602,
          0.16485020518302917, -0.005766286049038172, -0.10810886323451996,
          0.11400439590215683, 0.15334230661392212, -0.03884157910943031,
          -0.13895456492900848, 0.04439080134034157, -0.08996598422527313,
          0.06935221701860428, -0.0977875217795372, -0.16420038044452667,
          -0.019312968477606773, -0.047771088778972626, -0.19143125414848328,
          -0.13546983897686005, 0.07616709917783737, 0.019060013815760612,
          0.09761663526296616,
        ],
        [
          -0.14883209764957428, 0.1362849920988083, -0.17829708755016327,
          -0.04658285155892372, -0.06863067299127579, 0.03679969906806946,
          -0.11926198750734329, 0.0386071614921093, -0.1652536243200302,
          -0.12942160665988922, -0.15944042801856995, 0.10515067726373672,
          -0.04386943206191063, 0.03499060496687889, 0.11272943764925003,
          0.17247140407562256, -0.1790490448474884, 0.06790003925561905,
          0.17298585176467896, -0.08351676166057587, -0.17813001573085785,
          0.040929362177848816, -0.011879827827215195, 0.14022739231586456,
          0.030038796365261078, 0.07765378057956696, -0.000580011575948447,
          0.17800548672676086, 0.18321946263313293, 0.05698462203145027,
          -0.058127157390117645, -0.182443767786026, 0.08021284639835358,
          -0.061158791184425354, 0.0793752670288086, 0.1871255785226822,
          -0.15092547237873077,
        ],
        [
          -0.1902954876422882, 0.006503277458250523, -0.005374001804739237,
          0.10874869674444199, -0.09702330082654953, 0.16704584658145905,
          0.05049910396337509, -0.11209382861852646, -0.07484561949968338,
          0.057935453951358795, -0.029546746984124184, 0.09130296856164932,
          0.014695744961500168, 0.02069266140460968, 0.16324281692504883,
          0.03754222020506859, 0.12542903423309326, 0.10449592769145966,
          0.08458352088928223, 0.06975619494915009, 0.01769345998764038,
          0.21236659586429596, -0.16265374422073364, -0.06302142143249512,
          0.11799361556768417, 0.169568732380867, -0.10335151106119156,
          -0.11466116458177567, -0.10555452853441238, -0.1155802458524704,
          0.18175509572029114, -0.01909692957997322, -0.09956992417573929,
          0.0313611775636673, -0.10154552012681961, 0.13834148645401,
          0.12856708467006683,
        ],
        [
          0.03917704522609711, 0.11499383300542831, -0.14931313693523407,
          0.05646529793739319, 0.08767641335725784, -0.16402310132980347,
          -0.15257884562015533, -0.05953073874115944, -0.023257354274392128,
          -0.022872380912303925, -0.01032221969217062, 0.10989628732204437,
          0.06335709244012833, -0.03548932448029518, -0.043913353234529495,
          -0.19510573148727417, 0.10831078886985779, -0.06616636365652084,
          -0.1298125982284546, -0.21016937494277954, 0.04640205577015877,
          -0.022615430876612663, 0.19483737647533417, -0.12849031388759613,
          0.1341487169265747, -0.003796700155362487, 0.017271285876631737,
          0.02198105864226818, 0.08344899117946625, 0.1443958282470703,
          0.012881581671535969, -0.05089426413178444, -0.17160233855247498,
          -0.1511341780424118, 0.10984326153993607, 0.14449940621852875,
          -0.1858910769224167,
        ],
        [
          0.054200105369091034, 0.014498860575258732, 0.1752169132232666,
          0.019742999225854874, 0.19097095727920532, 0.07772322744131088,
          -0.11556795239448547, -0.039696283638477325, 0.18703143298625946,
          -0.18854407966136932, 0.109555184841156, 0.04384682700037956,
          0.14443451166152954, 0.017309358343482018, 0.13254667818546295,
          -0.10083803534507751, 0.061849649995565414, -0.11221721768379211,
          0.008153802715241909, 0.13774098455905914, -0.16289196908473969,
          0.13295520842075348, 0.17723184823989868, 0.021386444568634033,
          0.01872105896472931, -0.12620247900485992, -0.015086255967617035,
          0.09929395467042923, -0.13879765570163727, -0.02589898370206356,
          0.012742450460791588, -0.14729571342468262, -0.1441851407289505,
          0.03318866342306137, -0.16002146899700165, 0.1757342517375946,
          -0.12435167282819748,
        ],
        [
          0.16903746128082275, -0.0696127638220787, 0.12125807255506516,
          -0.16385668516159058, 0.0943547934293747, -0.05386727675795555,
          -0.1574348509311676, -0.05376823991537094, 0.10122357308864594,
          0.18351612985134125, 0.12497617304325104, -0.1542569100856781,
          0.18879862129688263, 0.02879343181848526, -0.035959016531705856,
          -0.04229584336280823, -0.1761319786310196, -0.06671963632106781,
          -0.023403404280543327, 0.10219822824001312, 0.16805949807167053,
          -0.00008005560812307522, 0.15747255086898804, 0.19900405406951904,
          -0.0849430039525032, 0.14392775297164917, -0.06755420565605164,
          -0.15015490353107452, 0.015401874668896198, -0.03183053806424141,
          0.07347436249256134, 0.0805891677737236, 0.05252294987440109,
          -0.090192511677742, -0.0384264774620533, 0.08630457520484924,
          -0.17513194680213928,
        ],
        [
          0.15127766132354736, 0.13159891963005066, -0.044347286224365234,
          -0.1395716518163681, -0.16379386186599731, -0.19627788662910461,
          0.1776488721370697, 0.1520085483789444, 0.10340307652950287,
          -0.18349722027778625, -0.13686412572860718, 0.0354749858379364,
          0.08097866922616959, 0.08108209818601608, 0.05104008689522743,
          0.05177647992968559, 0.006882518995553255, 0.036055102944374084,
          0.15082180500030518, -0.17362168431282043, -0.07764676958322525,
          0.03237539902329445, -0.06583601236343384, 0.025653431192040443,
          -0.19575390219688416, 0.07257528603076935, 0.11009559780359268,
          -0.04663688689470291, 0.1943235546350479, 0.010190397500991821,
          0.11345122754573822, -0.014467068016529083, 0.0385032519698143,
          -0.05874642729759216, -0.14469903707504272, -0.12087133526802063,
          -0.05358052998781204,
        ],
        [
          0.019980601966381073, 0.14677424728870392, 0.10973575711250305,
          -0.06411311775445938, -0.13534973561763763, 0.19825081527233124,
          -0.10022514313459396, 0.005430908873677254, -0.022960688918828964,
          0.05821707099676132, -0.14206118881702423, 0.18286554515361786,
          0.053971048444509506, -0.1333770751953125, -0.06921176612377167,
          0.1826804280281067, -0.09288285672664642, -0.0628705844283104,
          -0.11349870264530182, -0.18359772861003876, -0.00698626134544611,
          0.04755197465419769, -0.15478278696537018, 0.05291498079895973,
          0.08093997091054916, -0.038682788610458374, 0.17545130848884583,
          0.013175031170248985, 0.019251668825745583, 0.09548691660165787,
          -0.180492103099823, 0.14966566860675812, 0.027812199667096138,
          -0.1977081298828125, -0.09516779333353043, -0.09097950905561447,
          0.051145944744348526,
        ],
        [
          -0.13509728014469147, 0.048959068953990936, -0.05654603987932205,
          -0.14472456276416779, -0.007199043873697519, -0.018105531111359596,
          -0.06087587773799896, 0.17106477916240692, 0.03713628277182579,
          -0.05047674849629402, 0.10262563824653625, 0.17836184799671173,
          0.18150988221168518, -0.12944914400577545, -0.05597613751888275,
          0.025035718455910683, 0.18161006271839142, -0.03783923760056496,
          -0.1722874790430069, 0.09304291009902954, 0.052364107221364975,
          -0.16033415496349335, 0.16387119889259338, -0.07605075091123581,
          0.021602647379040718, 0.12343400716781616, -0.14595672488212585,
          -0.03487236425280571, 0.21313491463661194, 0.06130623817443848,
          -0.06262681633234024, -0.1545700877904892, 0.13745859265327454,
          0.00031836400739848614, -0.1902477741241455, -0.1675800383090973,
          0.08182619512081146,
        ],
        [
          0.09178180247545242, -0.08630818128585815, 0.08855544030666351,
          -0.18863044679164886, -0.19699843227863312, 0.18717193603515625,
          -0.07558875530958176, 0.11610241234302521, -0.09224136173725128,
          -0.18807527422904968, -0.14261475205421448, -0.13667026162147522,
          -0.16115409135818481, 0.1970764398574829, -0.04182528704404831,
          -0.032946858555078506, -0.1678093671798706, 0.1160397082567215,
          -0.015688640996813774, -0.06667043268680573, 0.07467012852430344,
          0.087980717420578, 0.157845601439476, -0.09532545506954193,
          -0.13151462376117706, -0.013489987701177597, -0.04212485998868942,
          0.1678268015384674, 0.10796342045068741, 0.09466765820980072,
          0.023999448865652084, 0.0569886714220047, -0.001996589358896017,
          0.0050172507762908936, -0.03703075647354126, -0.07020802795886993,
          0.020960891619324684,
        ],
        [
          -0.015218144282698631, -0.021086858585476875, -0.07356142997741699,
          0.17233438789844513, -0.0023520973045378923, 0.13154813647270203,
          0.1167466938495636, 0.19836241006851196, 0.18004372715950012,
          -0.06675922870635986, -0.02067512646317482, 0.07784812897443771,
          0.17311809957027435, 0.1661188304424286, -0.013072446919977665,
          -0.14285306632518768, 0.10139068961143494, -0.0567520447075367,
          -0.187648206949234, 0.11333438754081726, 0.012463876977562904,
          -0.007047557737678289, -0.1293337047100067, -0.08442382514476776,
          0.12112322449684143, -0.07833383232355118, 0.019960690289735794,
          0.06391994655132294, -0.07240629196166992, -0.11310860514640808,
          0.10144292563199997, -0.10194145888090134, -0.08094403892755508,
          0.0023454802576452494, -0.12700065970420837, 0.17261162400245667,
          0.14196616411209106,
        ],
        [
          0.19024547934532166, 0.17677339911460876, -0.029851442202925682,
          0.08467955887317657, -0.09408064931631088, 0.10589281469583511,
          -0.0978182926774025, 0.11321548372507095, 0.16414785385131836,
          0.01221028808504343, 0.06541625410318375, 0.10625847429037094,
          0.05251307785511017, 0.0785895437002182, -0.09365824609994888,
          -0.09457148611545563, 0.19368933141231537, -0.03195314109325409,
          -0.021637864410877228, 0.11296973377466202, -0.07094468921422958,
          0.09093128889799118, -0.13174700736999512, -0.03474104404449463,
          -0.11438577622175217, -0.03670310229063034, 0.13106442987918854,
          -0.025921206921339035, 0.03738933801651001, -0.19973333179950714,
          -0.16882841289043427, -0.0026007250417023897, 0.016066519543528557,
          -0.06018716096878052, -0.10808105021715164, 0.08495855331420898,
          -0.09934588521718979,
        ],
        [
          0.10904702544212341, -0.0424504354596138, -0.08352242410182953,
          0.020713167265057564, -0.08463993668556213, -0.11206945776939392,
          -0.1903107613325119, -0.04927628114819527, 0.14197276532649994,
          -0.12871527671813965, -0.11670283228158951, -0.15191330015659332,
          0.0947955995798111, -0.12508422136306763, -0.042698487639427185,
          -0.11383030563592911, 0.1811438649892807, 0.07009214162826538,
          -0.17560891807079315, -0.1384841650724411, 0.10298208147287369,
          0.09063581377267838, 0.11357742547988892, 0.058461934328079224,
          -0.12075024098157883, -0.029012881219387054, -0.009585133753716946,
          -0.06668396294116974, -0.05855521187186241, 0.07575029134750366,
          -0.046072691679000854, -0.12319740653038025, -0.04801243543624878,
          0.03972986713051796, -0.13113458454608917, 0.06332451850175858,
          -0.15404073894023895,
        ],
        [
          -0.07365591078996658, -0.03889855369925499, -0.1435612142086029,
          0.11284278333187103, -0.11278540641069412, 0.09886208921670914,
          -0.09325210750102997, -0.19105754792690277, 0.041993919759988785,
          0.04409816116094589, -0.03394543379545212, -0.012153244577348232,
          -0.11119580268859863, 0.004475151188671589, -0.07790226489305496,
          0.0416555181145668, -0.12727080285549164, -0.06988295167684555,
          0.169801265001297, 0.12317927926778793, -0.14742982387542725,
          -0.0636262372136116, 0.11332909762859344, -0.001465410110540688,
          -0.17717818915843964, 0.17093688249588013, -0.039677467197179794,
          0.1287279725074768, 0.11411215364933014, -0.04427201673388481,
          0.1207028478384018, -0.06766452640295029, 0.07173972576856613,
          0.1266612410545349, 0.1609686315059662, 0.02622411586344242,
          0.16170215606689453,
        ],
        [
          0.020212322473526, 0.018087904900312424, 0.16160449385643005,
          -0.03788751736283302, -0.12186409533023834, -0.18939968943595886,
          0.055786311626434326, -0.07524850219488144, 0.03851312771439552,
          0.05775578320026398, 0.12498752772808075, -0.12565433979034424,
          -0.13118581473827362, -0.029741482809185982, 0.13000594079494476,
          -0.006790452636778355, -0.1619936227798462, -0.00869814958423376,
          0.12267982959747314, -0.007693532854318619, -0.013512604869902134,
          -0.11906438320875168, 0.14229770004749298, 0.14099185168743134,
          -0.10504330694675446, -0.17571139335632324, -0.09654788672924042,
          -0.11226378381252289, 0.0510207898914814, 0.0030926440376788378,
          -0.006122201681137085, 0.06506357342004776, 0.06227979063987732,
          -0.19844555854797363, 0.023928245529532433, -0.13928954303264618,
          -0.07927040010690689,
        ],
        [
          0.012918866239488125, -0.18081627786159515, -0.11946789920330048,
          0.14173242449760437, -0.13147255778312683, 0.16479817032814026,
          0.1633313149213791, -0.02848498709499836, -0.09784995764493942,
          0.1913493275642395, -0.11583755910396576, -0.17998088896274567,
          -0.12516434490680695, 0.14807863533496857, -0.1629091054201126,
          -0.01332111656665802, -0.026400797069072723, -0.1760251820087433,
          0.09185788780450821, 0.08442684262990952, 0.1142953634262085,
          -0.07835527509450912, 0.1575854867696762, 0.05540644749999046,
          -0.07779715210199356, 0.10836747288703918, -0.0920616090297699,
          -0.0157572440803051, 0.18005183339118958, -0.08522084355354309,
          0.17826370894908905, -0.17140436172485352, 0.16914846003055573,
          0.05127595737576485, -0.13135965168476105, 0.1680789738893509,
          0.10925474017858505,
        ],
        [
          0.11085902899503708, 0.19710250198841095, -0.023244138807058334,
          -0.10591477155685425, 0.07796158641576767, 0.03836528956890106,
          -0.13430707156658173, -0.01183998305350542, -0.04555440694093704,
          0.16229555010795593, -0.10373695194721222, 0.09957407414913177,
          0.12126119434833527, -0.05585194379091263, -0.11360752582550049,
          -0.1765245944261551, -0.16914373636245728, 0.01443743146955967,
          -0.08709744364023209, -0.05588667094707489, -0.010208112187683582,
          0.13529179990291595, -0.1261463463306427, 0.1301300823688507,
          0.0508524551987648, 0.06584587693214417, -0.20851589739322662,
          0.1044757142663002, 0.07412371039390564, -0.06518560647964478,
          0.044770848006010056, 0.1204107403755188, -0.05087767541408539,
          0.08053857088088989, 0.1929144412279129, 0.1259315013885498,
          -0.028222423046827316,
        ],
        [
          0.12348921597003937, -0.1361948698759079, -0.11486391723155975,
          -0.10748983919620514, 0.07131704688072205, -0.005545246414840221,
          -0.07061909884214401, 0.1429927498102188, 0.03764957934617996,
          0.09589444100856781, -0.08833447098731995, -0.06548835337162018,
          -0.047720205038785934, 0.06187177821993828, -0.14357517659664154,
          0.11204970628023148, -0.007534571457654238, -0.1502692550420761,
          -0.13871656358242035, 0.1451602429151535, 0.07233978062868118,
          -0.1776089370250702, 0.15755410492420197, 0.014251421205699444,
          0.01823285035789013, -0.02582460455596447, 0.045148640871047974,
          0.05861012265086174, 0.1127329096198082, -0.020158080384135246,
          -0.09563727676868439, -0.11381688714027405, -0.09070441871881485,
          0.03130611032247543, -0.13190095126628876, -0.09522796422243118,
          0.0764031633734703,
        ],
        [
          0.04940253868699074, 0.1549483686685562, -0.0787527933716774,
          -0.04160275310277939, -0.11609317362308502, 0.018868708983063698,
          0.14173054695129395, -0.15066780149936676, 0.017942404374480247,
          0.015377230010926723, 0.1504979431629181, -0.19613102078437805,
          0.1063276082277298, -0.13386757671833038, 0.1709861159324646,
          0.007504070643335581, -0.02407027967274189, -0.18303096294403076,
          0.1589895486831665, -0.1975163370370865, -0.10872678458690643,
          0.15738032758235931, -0.0688699334859848, -0.14485590159893036,
          0.080335333943367, 0.09131871163845062, 0.07318411022424698,
          0.11122392863035202, -0.10270984470844269, -0.11137457937002182,
          -0.13735996186733246, -0.07672370970249176, 0.07011400163173676,
          0.16028106212615967, -0.019703693687915802, -0.04536907747387886,
          -0.03716607764363289,
        ],
        [
          0.018236713483929634, 0.019275780767202377, -0.1471770852804184,
          -0.18949100375175476, -0.0028814293909817934, 0.18160958588123322,
          -0.16174910962581635, 0.1484108418226242, -0.08727634698152542,
          0.18143008649349213, -0.044201917946338654, -0.06338512897491455,
          -0.09186501801013947, 0.0171591117978096, -0.06978192180395126,
          0.08588216453790665, -0.11873508244752884, 0.06835556030273438,
          -0.05276337265968323, 0.02702985890209675, -0.16385148465633392,
          -0.17999933660030365, -0.027849316596984863, 0.13872237503528595,
          0.13199667632579803, 0.19333580136299133, 0.16401568055152893,
          -0.005611768923699856, 0.13701610267162323, -0.12350407242774963,
          0.06150883808732033, 0.19811002910137177, 0.1755829155445099,
          0.03450394049286842, -0.008098562248051167, -0.10811589658260345,
          0.11222952604293823,
        ],
        [
          0.18594896793365479, 0.01341251004487276, -0.13041511178016663,
          0.0020362192299216986, 0.0976174995303154, 0.09183719754219055,
          0.011060792952775955, 0.09449046105146408, 0.01934993825852871,
          0.12092141807079315, 0.06928551942110062, -0.18880361318588257,
          -0.1346893161535263, 0.15455086529254913, -0.19430263340473175,
          0.07788702845573425, -0.15861158072948456, -0.15511013567447662,
          0.0721760019659996, -0.14126162230968475, 0.10614355653524399,
          -0.032214999198913574, -0.05572274327278137, -0.07375282794237137,
          0.031532496213912964, 0.2021895945072174, -0.12793660163879395,
          0.13494479656219482, -0.04552806541323662, 0.15140824019908905,
          0.022708915174007416, -0.1312883198261261, -0.024263644590973854,
          -0.06373003125190735, -0.012610547244548798, 0.016134267672896385,
          -0.09299562871456146,
        ],
        [
          0.09058994054794312, 0.16748595237731934, -0.18946143984794617,
          0.034658338874578476, -0.1669064462184906, -0.01425434835255146,
          0.1297340989112854, -0.07658547163009644, -0.15074267983436584,
          0.012885871343314648, -0.1456466019153595, 0.18727847933769226,
          0.1639328896999359, -0.045977093279361725, 0.18033047020435333,
          0.077645443379879, 0.13784825801849365, 0.1750764548778534,
          0.06931576132774353, 0.11698533594608307, -0.025308765470981598,
          0.0639118105173111, 0.016358213499188423, 0.16029711067676544,
          0.02816576510667801, -0.1452689915895462, 0.16923797130584717,
          0.14949995279312134, 0.14179250597953796, 0.06718634814023972,
          -0.0877171978354454, 0.07473137974739075, -0.09631452709436417,
          -0.16777165234088898, -0.13940171897411346, 0.1784493625164032,
          -0.14481128752231598,
        ],
        [
          0.1000252366065979, 0.12574006617069244, 0.0014271456748247147,
          0.19490984082221985, -0.10793475061655045, -0.10719998925924301,
          -0.1262238770723343, -0.11483139544725418, 0.029906466603279114,
          -0.03661789372563362, 0.17055927217006683, -0.19496527314186096,
          0.025490177795290947, 0.18535116314888, 0.04569665342569351,
          0.013718275353312492, -0.15520089864730835, -0.024065257981419563,
          0.18834741413593292, -0.06444753706455231, 0.11361641436815262,
          -0.13802221417427063, 0.11775816977024078, 0.1400781124830246,
          -0.13581261038780212, 0.0059764268808066845, -0.027134448289871216,
          -0.07101018726825714, -0.10536409169435501, -0.013765593059360981,
          0.07023310661315918, 0.11928833276033401, 0.17997191846370697,
          -0.09908097982406616, 0.1060367226600647, -0.10261142998933792,
          0.10147669911384583,
        ],
        [
          -0.035909730941057205, -0.050573207437992096, 0.17542847990989685,
          -0.13676808774471283, 0.09755091369152069, -0.09243377298116684,
          -0.1313782036304474, 0.14193478226661682, -0.10418979823589325,
          0.08251982927322388, -0.12679578363895416, -0.19950635731220245,
          -0.15221422910690308, 0.1654684990644455, 0.008282744325697422,
          -0.04802515357732773, -0.19923175871372223, 0.06282482296228409,
          0.013967152684926987, -0.12651793658733368, 0.06160327419638634,
          -0.06285196542739868, -0.09352558851242065, -0.1540052443742752,
          0.1647956222295761, -0.1883048564195633, -0.139729842543602,
          -0.04491749405860901, 0.18654069304466248, -0.16190588474273682,
          -0.16278596222400665, 0.12692008912563324, -0.0018436663085594773,
          0.037447698414325714, -0.09938174486160278, 0.003718832740560174,
          -0.053645774722099304,
        ],
        [
          0.1412147581577301, -0.13815920054912567, 0.1676817685365677,
          -0.12668821215629578, -0.07113378494977951, 0.07778550684452057,
          0.03297780454158783, -0.15696196258068085, -0.050268273800611496,
          -0.06313879787921906, -0.007746870629489422, -0.02864590659737587,
          0.0796571671962738, -0.1187489852309227, 0.0448157861828804,
          0.16040512919425964, 0.18195831775665283, 0.0758921205997467,
          -0.08385279029607773, -0.015319873578846455, -0.1696770042181015,
          0.03528837114572525, -0.10267538577318192, 0.01937972381711006,
          0.17536526918411255, 0.1857696771621704, 0.12476490437984467,
          -0.07278095185756683, 0.02024706080555916, -0.13371118903160095,
          -0.045218370854854584, -0.10529690980911255, 0.17388679087162018,
          -0.07504411786794662, -0.033585354685783386, -0.11934041231870651,
          0.09730525314807892,
        ],
        [
          0.1495475322008133, -0.10526727139949799, 0.18633818626403809,
          -0.08998992294073105, -0.13553085923194885, 0.10592637956142426,
          -0.18187229335308075, 0.18759123980998993, -0.14760257303714752,
          -0.08685334771871567, -0.15775051712989807, 0.08215609192848206,
          -0.12946954369544983, 0.061972882598638535, 0.17815811932086945,
          0.14557373523712158, 0.19806021451950073, -0.09046570211648941,
          0.046769969165325165, -0.03964831307530403, 0.00960524007678032,
          0.007825538516044617, 0.08560716360807419, -0.07183446735143661,
          0.06135151535272598, -0.1853105127811432, -0.15844173729419708,
          0.022293096408247948, -0.15494965016841888, -0.10526684671640396,
          0.04909953847527504, 0.1264072209596634, -0.07919768244028091,
          -0.08827851712703705, -0.05199449509382248, 0.1428142786026001,
          -0.15085777640342712,
        ],
        [
          -0.05508367344737053, 0.09729476273059845, -0.17283210158348083,
          -0.11239758133888245, 0.09173426777124405, 0.09917104244232178,
          -0.09143879264593124, -0.05190335959196091, -0.1756635308265686,
          -0.1372993439435959, 0.15373528003692627, -0.1339704394340515,
          0.12933489680290222, -0.06544740498065948, 0.16353049874305725,
          -0.009672083891928196, -0.15646813809871674, 0.09591837227344513,
          0.11832041293382645, 0.04671280086040497, 0.12724223732948303,
          -0.20029306411743164, -0.15686580538749695, 0.017136987298727036,
          0.16654427349567413, 0.07745139300823212, -0.07098119705915451,
          0.10615158826112747, -0.01128919143229723, -0.1381412148475647,
          0.17516638338565826, -0.08939258754253387, -0.14666202664375305,
          0.15412235260009766, -0.15502533316612244, 0.16264526546001434,
          0.0330345444381237,
        ],
        [
          0.1522965133190155, 0.05129460617899895, 0.004689602646976709,
          0.06095832958817482, 0.11410702019929886, -0.09668870270252228,
          0.07151202857494354, 0.14330600202083588, -0.08996234834194183,
          -0.1724889576435089, -0.0595853254199028, 0.028426581993699074,
          -0.0964188277721405, -0.14793452620506287, 0.0013294315431267023,
          -0.09094063192605972, -0.004846911411732435, 0.192891463637352,
          0.09795476496219635, -0.17934097349643707, 0.1874743551015854,
          -0.052100811153650284, 0.12724100053310394, 0.011465967632830143,
          -0.022221585735678673, 0.10556790977716446, -0.03052937611937523,
          -0.00608604121953249, 0.023904412984848022, -0.05266623944044113,
          -0.06986422836780548, 0.12919124960899353, -0.06943365186452866,
          0.019820166751742363, -0.009172394871711731, 0.07178378850221634,
          0.10588587820529938,
        ],
        [
          0.1537070870399475, 0.07440846413373947, -0.09769067913293839,
          -0.0641067624092102, 0.1279085874557495, 0.1637251228094101,
          -0.10491276532411575, 0.1869463324546814, 0.13312683999538422,
          -0.1570960432291031, 0.18404020369052887, 0.0034576349426060915,
          -0.07562822103500366, 0.15784597396850586, 0.0466281957924366,
          0.15917159616947174, -0.03683372586965561, -0.11918443441390991,
          -0.05383101850748062, 0.1713809072971344, -0.04063084349036217,
          0.11977284401655197, 0.10276918113231659, 0.06922577321529388,
          0.11673405021429062, -0.10012904554605484, -0.0296563059091568,
          0.09699001163244247, 0.048168692737817764, -0.19270625710487366,
          -0.2054547667503357, -0.1993529051542282, 0.11922315508127213,
          0.14063586294651031, -0.10744661837816238, -0.06856149435043335,
          0.017152298241853714,
        ],
        [
          -0.037858277559280396, 0.02886335924267769, -0.07187876850366592,
          -0.061437517404556274, 0.19346444308757782, 0.15381687879562378,
          0.177791565656662, 0.17969922721385956, -0.10757412761449814,
          -0.0415237694978714, -0.09284527599811554, -0.18924234807491302,
          -0.0703372061252594, 0.05128271505236626, 0.19683951139450073,
          -0.024011611938476562, -0.04982498288154602, 0.048393916338682175,
          0.19720061123371124, -0.060768552124500275, -0.05607667937874794,
          0.014290991239249706, -0.1456838697195053, -0.10171373188495636,
          -0.1597079187631607, -0.08722925931215286, 0.00751751521602273,
          -0.0926380529999733, -0.14944235980510712, 0.16708716750144958,
          -0.1572045534849167, -0.17966140806674957, 0.011005386710166931,
          0.19429022073745728, 0.009810051880776882, -0.1378657966852188,
          -0.03309798240661621,
        ],
        [
          -0.08745238184928894, -0.0372820720076561, -0.16194602847099304,
          -0.1951155811548233, -0.19431142508983612, 0.14735394716262817,
          0.12169913947582245, 0.03622274845838547, -0.04088602215051651,
          -0.13864679634571075, -0.14682269096374512, -0.10277159512042999,
          -0.028707098215818405, 0.10453654080629349, -0.17801277339458466,
          -0.001383210881613195, 0.12810717523097992, -0.014883055351674557,
          -0.042987801134586334, 0.07868706434965134, -0.18420305848121643,
          -0.20527935028076172, -0.0024723412934690714, 0.021621357649564743,
          -0.020281730219721794, -0.004016753286123276, 0.15141920745372772,
          -0.16249243915081024, -0.05426895245909691, -0.13647186756134033,
          0.12907689809799194, -0.16022631525993347, 0.15355834364891052,
          -0.12980878353118896, 0.13560029864311218, -0.17787988483905792,
          -0.04922539368271828,
        ],
        [
          -0.035600923001766205, -0.13618186116218567, -0.08918864279985428,
          -0.02746911533176899, -0.046505942940711975, 0.04295310378074646,
          -0.08472643047571182, -0.025340711697936058, 0.013223526999354362,
          0.09275155514478683, -0.18985536694526672, -0.0659768134355545,
          0.1312037706375122, 0.19106875360012054, 0.19227050244808197,
          -0.12515679001808167, 0.09980124980211258, -0.068628691136837,
          0.032377567142248154, -0.17436178028583527, 0.042598508298397064,
          0.14303047955036163, -0.06341418623924255, -0.0422978512942791,
          -0.14817577600479126, -0.020960330963134766, -0.11403390020132065,
          -0.11011381447315216, 0.1401609480381012, 0.09917425364255905,
          -0.06266830116510391, 0.07406020164489746, -0.08468486368656158,
          0.1049831360578537, 0.18500369787216187, -0.12969738245010376,
          0.19243519008159637,
        ],
        [
          0.038586873561143875, 0.19556839764118195, -0.08606012165546417,
          -0.11912045627832413, -0.17648303508758545, -0.14542634785175323,
          0.037579867988824844, 0.07776057720184326, -0.12086069583892822,
          0.1730954945087433, 0.16081146895885468, -0.11836589872837067,
          -0.16586780548095703, -0.08015722781419754, 0.16842302680015564,
          0.13902267813682556, -0.034419067203998566, -0.12838172912597656,
          0.11375826597213745, -0.0571042075753212, 0.046423930674791336,
          0.25811511278152466, -0.10147292166948318, 0.12164711207151413,
          0.13414530456066132, 0.11039063334465027, 0.10716456174850464,
          -0.05243373662233353, -0.02213182859122753, -0.037905871868133545,
          -0.023997914046049118, 0.03161483258008957, -0.12724144756793976,
          0.1993442326784134, -0.17681090533733368, -0.10185007005929947,
          -0.1492985039949417,
        ],
        [
          0.07070852071046829, 0.0070675392635166645, -0.12495376169681549,
          0.12079925835132599, 0.04769425839185715, 0.056572820991277695,
          -0.0641760528087616, 0.14226917922496796, -0.10141844302415848,
          -0.14624068140983582, -0.04753611236810684, 0.04755232855677605,
          0.06305302679538727, 0.08117137104272842, -0.007506905123591423,
          -0.06336105614900589, -0.17069028317928314, 0.12834668159484863,
          -0.06476791948080063, 0.05605287104845047, 0.038527023047208786,
          0.08786998689174652, -0.03293563053011894, 0.180832177400589,
          -0.07063615322113037, 0.10996592789888382, 0.0022703162394464016,
          -0.0941624864935875, 0.002404307248070836, 0.1850716918706894,
          -0.03527403250336647, -0.17962434887886047, -0.1728532314300537,
          -0.09999153763055801, -0.14566898345947266, -0.18093737959861755,
          -0.06096840277314186,
        ],
        [
          -0.184798464179039, 0.0019439607858657837, 0.04643741622567177,
          -0.17736001312732697, 0.06642672419548035, 0.12651944160461426,
          0.06125250086188316, 0.015462607145309448, -0.05134618282318115,
          0.027751319110393524, -0.16328592598438263, 0.09621699899435043,
          0.12543299794197083, -0.07683130353689194, 0.18832071125507355,
          -0.010098065249621868, -0.13632148504257202, -0.006923863664269447,
          -0.11297667026519775, -0.04760804772377014, -0.06877864897251129,
          0.04351356253027916, -0.06312623620033264, -0.20434685051441193,
          0.12404850870370865, -0.12005317956209183, -0.03205045685172081,
          -0.17457826435565948, -0.15971074998378754, 0.17760275304317474,
          -0.09805415570735931, 0.12179027497768402, 0.19758577644824982,
          -0.11826249212026596, 0.04111601784825325, -0.008649284020066261,
          -0.06369815766811371,
        ],
        [
          -0.058223430067300797, -0.029472237452864647, -0.04512033984065056,
          -0.11208171397447586, 0.18120548129081726, 0.08976495265960693,
          0.14711995422840118, -0.16663475334644318, 0.10356458276510239,
          0.033979084342718124, -0.15694840252399445, -0.0827801451086998,
          -0.09757688641548157, 0.15061530470848083, -0.18067434430122375,
          -0.07224848866462708, 0.005346829071640968, -0.16411757469177246,
          -0.10614554584026337, -0.06151051074266434, -0.05402504652738571,
          0.13877323269844055, 0.13285119831562042, -0.016743892803788185,
          0.021274594590067863, -0.14307180047035217, 0.0280118640512228,
          -0.17378072440624237, 0.08994550257921219, 0.07917679101228714,
          -0.19189602136611938, -0.1442675143480301, -0.19222113490104675,
          -0.1251606047153473, -0.11780284345149994, -0.09088514745235443,
          0.1972760111093521,
        ],
        [
          -0.03269844129681587, -0.19547535479068756, -0.19789554178714752,
          0.07421296834945679, -0.025838129222393036, -0.17858204245567322,
          0.06569906324148178, 0.09143891930580139, 0.1978461742401123,
          0.1889638900756836, 0.061111047863960266, 0.12285874038934708,
          0.12418779730796814, -0.1850893497467041, 0.17151059210300446,
          0.10720983147621155, -0.0957888513803482, 0.09275104105472565,
          -0.03866147994995117, 0.010621251538395882, 0.11717262119054794,
          -0.19392670691013336, 0.19631105661392212, -0.033081669360399246,
          0.1486099362373352, -0.0847153291106224, 0.050897326320409775,
          0.13461191952228546, 0.05641936883330345, -0.17274481058120728,
          0.037083301693201065, -0.19268198311328888, 0.14319062232971191,
          -0.18741703033447266, 0.0550127811729908, 0.14219146966934204,
          -0.19975873827934265,
        ],
        [
          0.1041654497385025, 0.049644820392131805, 0.04896770417690277,
          0.18783831596374512, 0.13083416223526, -0.05753554403781891,
          -0.07109274715185165, -0.08235764503479004, -0.02721775323152542,
          -0.04146548733115196, 0.076884925365448, 0.138297900557518,
          -0.17692577838897705, -0.04945509135723114, 0.024858754128217697,
          -0.052847784012556076, -0.11193408071994781, -0.05935853347182274,
          -0.1462169885635376, -0.021067675203084946, 0.13970398902893066,
          0.1026831865310669, -0.1466393917798996, -0.1400204449892044,
          0.11715047061443329, 0.12474597990512848, 0.014569967053830624,
          -0.12672579288482666, 0.15053418278694153, -0.06364830583333969,
          -0.13513679802417755, 0.05347199738025665, 0.07318982481956482,
          0.1405314952135086, -0.07014069706201553, -0.09072692692279816,
          -0.1721293181180954,
        ],
        [
          -0.09342724084854126, 0.004193250555545092, -0.13047419488430023,
          0.1648223102092743, -0.07886018604040146, -0.14484365284442902,
          -0.003125301329419017, 0.14177139103412628, 0.04215407371520996,
          0.1374330073595047, 0.05230702832341194, -0.1737944334745407,
          -0.009835980832576752, 0.15805363655090332, -0.1748381406068802,
          0.08276548236608505, -0.001068394398316741, 0.08314993232488632,
          0.028311634436249733, -0.22677908837795258, 0.09756147116422653,
          0.07695568352937698, 0.11268355697393417, -0.11353601515293121,
          -0.04257074370980263, 0.021060151979327202, -0.0774671733379364,
          -0.12280068546533585, -0.10173767805099487, 0.01979520544409752,
          -0.10550687462091446, -0.16020306944847107, -0.13947156071662903,
          -0.16183577477931976, 0.18958911299705505, 0.1225438117980957,
          0.16992469131946564,
        ],
        [
          0.1338723748922348, 0.031190188601613045, -0.05751831457018852,
          0.06393566727638245, 0.16040316224098206, -0.089322529733181,
          0.040020652115345, -0.08879970759153366, -0.004720840137451887,
          0.10660038143396378, -0.03598283603787422, -0.06978856027126312,
          -0.07266853749752045, 0.07450031489133835, 0.12635812163352966,
          -0.10834327340126038, -0.18279920518398285, -0.14981310069561005,
          -0.13041073083877563, -0.165746808052063, -0.08704449981451035,
          0.11919356882572174, -0.0243948046118021, 0.1876509040594101,
          0.14175806939601898, 0.09052951633930206, -0.05012888461351395,
          0.16548235714435577, -0.007545710075646639, 0.07576445490121841,
          0.08892311900854111, -0.09476545453071594, -0.013029127381742,
          0.09552367776632309, -0.1198272630572319, 0.11452333629131317,
          -0.098541758954525,
        ],
        [
          0.11039915680885315, 0.18264910578727722, 0.1078004539012909,
          0.012103583663702011, 0.011801747605204582, 0.0023266461212188005,
          -0.05856233090162277, -0.09113112837076187, 0.12121111154556274,
          -0.08069931715726852, 0.18722841143608093, -0.18951575458049774,
          0.10085885226726532, 0.018460337072610855, 0.15162256360054016,
          0.11589857935905457, 0.17899508774280548, 0.004515789449214935,
          -0.15565194189548492, -0.12740376591682434, 0.03476325049996376,
          0.11820650100708008, 0.19641955196857452, -0.13444924354553223,
          0.17639711499214172, -0.1432296484708786, 0.004265047609806061,
          -0.19981487095355988, -0.18574929237365723, -0.14036285877227783,
          -0.012412961572408676, 0.07320325821638107, 0.1971118301153183,
          0.06513208150863647, -0.1601003110408783, 0.009346213191747665,
          -0.03899470344185829,
        ],
        [
          0.18991398811340332, -0.006837563589215279, 0.108803391456604,
          0.19028814136981964, -0.08654937148094177, -0.08418276160955429,
          -0.09512297064065933, 0.13514690101146698, 0.1491173952817917,
          -0.06761183589696884, -0.18593263626098633, -0.031350962817668915,
          0.08066257834434509, -0.10825737565755844, -0.04637017846107483,
          0.18501703441143036, -0.19962447881698608, -0.17908824980258942,
          -0.12355583906173706, 0.02126806415617466, -0.14449487626552582,
          0.011878164485096931, -0.06547853350639343, -0.010864817537367344,
          -0.15343065559864044, 0.011374268680810928, -0.03664591908454895,
          -0.087972491979599, -0.17003333568572998, -0.14646252989768982,
          0.018136845901608467, -0.15522406995296478, -0.00563633069396019,
          0.19507217407226562, -0.11249487102031708, -0.1582341343164444,
          -0.026311786845326424,
        ],
        [
          -0.1787969022989273, 0.1792844980955124, 0.019186245277523994,
          0.003464847570285201, 0.1396685391664505, -0.151316836476326,
          -0.051805827766656876, 0.10258086025714874, 0.05221835896372795,
          0.16852828860282898, -0.06579171121120453, 0.13150452077388763,
          -0.004320361185818911, 0.19224658608436584, -0.057849425822496414,
          0.15728019177913666, -0.19428372383117676, -0.18897663056850433,
          -0.1961037963628769, 0.04206495359539986, -0.11775941401720047,
          0.13603036105632782, 0.16497424244880676, -0.1423550546169281,
          0.06220077723264694, 0.12349574267864227, 0.06411480158567429,
          -0.03788430616259575, -0.09604324400424957, 0.06331836432218552,
          -0.009475886821746826, 0.04561741277575493, 0.13561879098415375,
          -0.061879198998212814, -0.10319263488054276, 0.03751981258392334,
          -0.19231219589710236,
        ],
        [
          -0.13423877954483032, -0.0395834781229496, -0.07768068462610245,
          0.13228948414325714, -0.047162361443042755, -0.15169879794120789,
          -0.17634469270706177, -0.03778710216283798, -0.16589057445526123,
          0.0522184818983078, 0.01718553528189659, -0.033781711012125015,
          -0.008068311959505081, 0.09555529803037643, -0.18799707293510437,
          0.1300724893808365, -0.04874240607023239, 0.010948862880468369,
          -0.18373538553714752, -0.22024881839752197, -0.08209049701690674,
          -0.05423007532954216, -0.15246237814426422, 0.11242452263832092,
          0.13023877143859863, -0.16278775036334991, 0.1378343105316162,
          -0.04626849666237831, 0.06401193141937256, 0.0007685294840484858,
          -0.0438845232129097, 0.10623709857463837, -0.01738774962723255,
          -0.17673279345035553, 0.1426474153995514, -0.005495545454323292,
          0.18145035207271576,
        ],
        [
          -0.0392926000058651, -0.093939408659935, 0.08288145810365677,
          -0.19197681546211243, 0.09008435159921646, 0.1366770714521408,
          0.003340266179293394, -0.0992811769247055, 0.15952168405056,
          -0.005008203908801079, -0.054687563329935074, -0.10694137960672379,
          -0.12108407914638519, 0.1788288801908493, -0.05456538498401642,
          -0.03050857223570347, -0.04075583070516586, -0.17580093443393707,
          0.04187091439962387, -0.07714337855577469, 0.20676885545253754,
          0.2017611861228943, -0.18911604583263397, -0.17057545483112335,
          0.10802269726991653, 0.15767966210842133, 0.01630173996090889,
          -0.11414072662591934, 0.11283764243125916, -0.18801577389240265,
          0.11055166274309158, -0.1599143147468567, -0.0792226567864418,
          -0.042257390916347504, -0.12019332498311996, 0.04822270944714546,
          -0.1017293930053711,
        ],
        [
          -0.1738414466381073, -0.120338574051857, -0.012063778005540371,
          -0.10137725621461868, 0.11699624359607697, -0.08424501866102219,
          0.029774101451039314, 0.16260774433612823, -0.11004702746868134,
          0.013699262402951717, 0.05874410271644592, -0.19119742512702942,
          0.06074727699160576, -0.166826069355011, 0.18460999429225922,
          0.06863611191511154, -0.157739520072937, -0.018446102738380432,
          -0.04289497062563896, 0.2091042399406433, 0.031091799959540367,
          0.12053647637367249, 0.027329202741384506, -0.1598326563835144,
          -0.1490381509065628, 0.12154795974493027, 0.005920780822634697,
          -0.13274146616458893, -0.1444174200296402, 0.09484401345252991,
          0.06909622251987457, 0.03759363293647766, 0.0694756731390953,
          0.14665627479553223, -0.008060304448008537, 0.1257891058921814,
          -0.17802035808563232,
        ],
        [
          0.001655532163567841, 0.02184128761291504, 0.0617888867855072,
          -0.04159572720527649, -0.16876080632209778, -0.08323923498392105,
          0.01462524477392435, -0.0994211956858635, 0.05487709119915962,
          -0.16255387663841248, -0.011796317994594574, 0.13004225492477417,
          0.10518277436494827, 0.030283896252512932, 0.1581176072359085,
          -0.11166403442621231, -0.1991824358701706, -0.0878428965806961,
          -0.11642198264598846, 0.02679726481437683, -0.15977869927883148,
          -0.15526802837848663, -0.1294177919626236, 0.09440694749355316,
          -0.013237948529422283, -0.12996487319469452, 0.017447084188461304,
          -0.025691905990242958, -0.03388931229710579, 0.19259540736675262,
          -0.08899304270744324, 0.18465402722358704, -0.054029058665037155,
          0.018566150218248367, -0.07742466032505035, -0.048566561192274094,
          -0.06075967103242874,
        ],
        [
          0.08703088760375977, 0.1495770514011383, 0.0023014454636722803,
          0.15227748453617096, 0.09119560569524765, -0.12677551805973053,
          -0.0379628986120224, -0.13397085666656494, 0.19106294214725494,
          -0.09901493787765503, -0.17797215282917023, 0.036538317799568176,
          0.16258396208286285, 0.0117793595418334, -0.025777684524655342,
          0.06668753921985626, 0.14649221301078796, 0.1961543709039688,
          -0.05361739173531532, 0.09041038155555725, -0.1319415122270584,
          -0.017513949424028397, 0.18355607986450195, 0.10860390961170197,
          0.00026718201115727425, -0.16425234079360962, 0.10847298800945282,
          0.076164610683918, 0.11952430009841919, -0.0515538714826107,
          -0.040876831859350204, 0.17412330210208893, -0.1689169853925705,
          -0.1467404067516327, -0.006547593045979738, 0.013498668558895588,
          0.19995194673538208,
        ],
        [
          0.124839648604393, 0.07308605313301086, -0.18107342720031738,
          0.0031210838351398706, -0.10476360470056534, 0.0846862643957138,
          0.07260642945766449, -0.04858503118157387, 0.1020699217915535,
          0.13914591073989868, -0.07569792866706848, -0.03500932455062866,
          0.1023608148097992, -0.10099443793296814, 0.10739745944738388,
          0.01722424104809761, 0.18782013654708862, -0.07549130916595459,
          0.03641993924975395, 0.03403780981898308, -0.10599888861179352,
          0.21389499306678772, -0.18312354385852814, -0.1541113257408142,
          0.1257137805223465, 0.18218903243541718, 0.05982722342014313,
          -0.08375617116689682, -0.09692158550024033, 0.11321461200714111,
          0.008044897578656673, -0.15378360450267792, -0.07930280268192291,
          -0.17811428010463715, -0.0850372314453125, 0.027563942596316338,
          0.16289022564888,
        ],
      ],
      biases: [
        -0.005241606384515762, 0.1164616048336029, 0.002837838139384985,
        0.07106471806764603, 0.06902725994586945, -0.05278991907835007,
        0.1799614429473877, -0.12188278138637543, -0.05001328885555267,
        0.11000406742095947, 0.008646395057439804, 0.12559355795383453,
        -0.14101701974868774, 0.07125413417816162, -0.10929610580205917,
        0.13470260798931122, 0.15116986632347107, 0.07581384479999542,
        0.06856740266084671, 0.17600147426128387, 0.0027872894424945116,
        0.11223842948675156, 0.176870658993721, 0.07183120399713516,
        -0.14168979227542877, -0.14987729489803314, 0.17117895185947418,
        0.11684143543243408, 0.00014848461432848126, -0.11274271458387375,
        -0.19098730385303497, 0.1367335468530655, -0.19897149503231049,
        -0.13320292532444, 0.017701489850878716, -0.16706445813179016,
        0.12575753033161163, 0.07087098807096481, 0.047103043645620346,
        -0.09945759177207947, -0.06798562407493591, 0.16553212702274323,
        -0.16741511225700378, 0.09178420901298523, -0.09968207031488419,
        -0.0028616732452064753, -0.06224155053496361, -0.19631288945674896,
        -0.13300056755542755, -0.1926768720149994, -0.013420806266367435,
        0.09775105863809586, -0.1668664664030075, -0.040709249675273895,
        0.0704873576760292, 0.04922764003276825, -0.19031499326229095,
        0.001740894978865981, -0.008282448165118694, 0.17272919416427612,
        0.07174515724182129, -0.18035022914409637, 0.06308143585920334,
        -0.0997176244854927, -0.16871148347854614, 0.09689575433731079,
        0.005723309703171253, -0.1109129935503006, 0.17181482911109924,
        -0.07300831377506256, -0.1259661316871643, 0.027659432962536812,
        0.05624791234731674, 0.16470107436180115, 0.004013863392174244,
        0.07301266491413116, -0.13441413640975952, 0.16296623647212982,
        -0.1334265023469925, -0.05080066993832588, 0.10506951063871384,
        -0.0526219867169857, -0.020485611632466316, -0.15007591247558594,
        -0.05545680597424507, 0.16521544754505157, -0.041291240602731705,
        0.12110275775194168, -0.12359625846147537, 0.011954900808632374,
        0.14777012169361115, -0.12646493315696716, -0.08364838361740112,
        -0.06725148111581802, 0.14993822574615479, -0.12381444871425629,
        -0.07278591394424438, 0.12026358395814896, 0.018561407923698425,
        -0.1728208065032959,
      ],
    },
    {
      weights: [
        [
          -0.14760896563529968, -0.18604226410388947, -0.1650114506483078,
          -0.20234742760658264, 0.004330061376094818, 0.06947125494480133,
          0.1440804898738861, -0.21716783940792084, 0.08817152678966522,
          0.008241190575063229, 0.12029296904802322, 0.07180308550596237,
          -0.026673277840018272, -0.056057266891002655, 0.1750030368566513,
          -0.1485193818807602, 0.13955679535865784, 0.1303001195192337,
          -0.12799164652824402, -0.016346048563718796, 0.03335868567228317,
          -0.20291903614997864, -0.05726718157529831, 0.10096083581447601,
          -0.02274082973599434, 0.14512762427330017, -0.2067779153585434,
          0.007160817738622427, -0.08837990462779999, -0.02516944147646427,
          0.0014125443994998932, -0.08165200054645538, 0.13652704656124115,
          -0.16006074845790863, -0.18805404007434845, 0.15354013442993164,
          -0.02054557204246521, 0.02238592877984047, -0.09119881689548492,
          0.023652462288737297, -0.03918575495481491, 0.0488496758043766,
          0.054591160267591476, -0.01701887883245945, -0.08134742081165314,
          -0.12326963990926743, -0.1319528967142105, -0.22546164691448212,
          -0.003482622327283025, 0.16753236949443817, 0.12001804262399673,
          0.02341669425368309, -0.1282477229833603, -0.07653950154781342,
          -0.14287284016609192, 0.034145474433898926, -0.20737312734127045,
          -0.0683383196592331, -0.18027740716934204, 0.16778898239135742,
          -0.0018776127835735679, -0.03842991590499878, -0.015758216381072998,
          0.08521530777215958, 0.07124226540327072, -0.10262198746204376,
          0.07630473375320435, 0.02170242741703987, 0.1498434990644455,
          0.07698606699705124, -0.060853686183691025, -0.16322767734527588,
          -0.028073638677597046, -0.10650387406349182, -0.019670529291033745,
          0.0014153687516227365, -0.008794241584837437, -0.03510185331106186,
          -0.001810368150472641, -0.18197615444660187, -0.06466080993413925,
          -0.06082192435860634, -0.08175944536924362, -0.19473274052143097,
          -0.179898202419281, -0.04147147759795189, 0.07880965620279312,
          -0.018723130226135254, 0.1784805953502655, 0.1373605877161026,
          0.0731637105345726, 0.11986003816127777, -0.20850837230682373,
          -0.05627240985631943, -0.16584338247776031, 0.141750305891037,
          -0.21943499147891998, -0.19807025790214539, 0.04240847006440163,
          -0.12675350904464722,
        ],
        [
          0.006691962014883757, -0.009367878548800945, 0.019840918481349945,
          -0.07211674004793167, -0.10275444388389587, -0.21709465980529785,
          0.08471979945898056, -0.11640127003192902, -0.0897614061832428,
          0.14218032360076904, -0.13911688327789307, -0.10472368448972702,
          -0.2200240194797516, -0.21286062896251678, -0.06317154318094254,
          0.11924441903829575, 0.035464465618133545, 0.09653209894895554,
          -0.1663183867931366, -0.06702917069196701, -0.050311021506786346,
          -0.0955984815955162, 0.06691864132881165, -0.10927572101354599,
          0.07922742515802383, -0.04809897020459175, -0.042521726340055466,
          0.07844483852386475, 0.07710415869951248, 0.1416582614183426,
          -0.11795363575220108, -0.1453140527009964, -0.03187555447220802,
          -0.16298078000545502, 0.03649142384529114, -0.1411915123462677,
          -0.200906440615654, -0.1554350107908249, -0.04885176196694374,
          0.01954032853245735, -0.09050638228654861, 0.07878950238227844,
          -0.19038769602775574, -0.04753196984529495, -0.09785789251327515,
          0.12552820146083832, -0.14062237739562988, 0.16533641517162323,
          -0.017088985070586205, 0.09669940918684006, -0.1899118572473526,
          0.06679830700159073, 0.12507006525993347, 0.0037839242722839117,
          -0.07040105015039444, 0.0005086322198621929, 0.020596129819750786,
          0.12524168193340302, -0.09746842831373215, -0.11880049109458923,
          -0.07234577089548111, -0.12240871042013168, 0.20519866049289703,
          0.09759169816970825, 0.1637309342622757, -0.10011857748031616,
          0.014740894548594952, 0.08782702684402466, -0.09127290546894073,
          -0.11786600947380066, 0.10061174631118774, -0.19310031831264496,
          0.08090269565582275, -0.17506824433803558, 0.11725439131259918,
          -0.1665807068347931, 0.1887892484664917, -0.13110120594501495,
          -0.1650668829679489, 0.0901331827044487, 0.12552502751350403,
          0.1397794485092163, -0.11963135749101639, 0.13878199458122253,
          0.04736073315143585, -0.12283001840114594, -0.14889675378799438,
          -0.0961756557226181, -0.07827810198068619, 0.056235965341329575,
          0.11973844468593597, -0.026613613590598106, 0.2421528697013855,
          0.0034857820719480515, 0.08372347801923752, -0.09563639760017395,
          0.04818485304713249, -0.14374113082885742, 0.1188013106584549,
          -0.02332977205514908,
        ],
        [
          -0.12390013039112091, 0.10037390142679214, 0.02756955288350582,
          -0.05854719132184982, -0.006301330868154764, 0.058948107063770294,
          0.06475372612476349, 0.10278431326150894, 0.11961603164672852,
          0.018569009378552437, 0.020839089527726173, 0.011588062159717083,
          -0.07303950190544128, 0.17449110746383667, -0.20395955443382263,
          -0.14749786257743835, -0.18680372834205627, 0.12694841623306274,
          -0.04618173837661743, -0.0889168381690979, -0.053614210337400436,
          0.05881066620349884, 0.20112468302249908, -0.05601302906870842,
          0.16530126333236694, -0.12746673822402954, -0.13662241399288177,
          -0.20850853621959686, -0.08830150961875916, -0.12414827197790146,
          0.13487422466278076, -0.13914433121681213, 0.16842949390411377,
          -0.04051743820309639, 0.22563347220420837, 0.22945305705070496,
          -0.10957019031047821, -0.15851886570453644, 0.04564087465405464,
          0.09712501615285873, -0.22255145013332367, -0.11796074360609055,
          -0.12353310734033585, -0.1829017549753189, 0.12174560874700546,
          -0.07796034961938858, -0.049859095364809036, -0.07875467091798782,
          0.10011214762926102, 0.1169980838894844, -0.12982897460460663,
          -0.02241171896457672, -0.06199794262647629, -0.1285148561000824,
          0.025595055893063545, -0.03785030171275139, 0.054461803287267685,
          0.005039357114583254, -0.09835425764322281, -0.15415321290493011,
          -0.07446002215147018, 0.14210504293441772, 0.08648045361042023,
          -0.02592535689473152, 0.1428842693567276, 0.15576428174972534,
          -0.08100175857543945, 0.09263357520103455, -0.023683534935116768,
          -0.20006544888019562, -0.0919218510389328, -0.14085079729557037,
          0.028121186420321465, 0.12784366309642792, -0.08831913769245148,
          -0.15319471061229706, -0.03588524088263512, 0.17733122408390045,
          0.08328020572662354, 0.18052184581756592, -0.045374736189842224,
          0.15797346830368042, -0.04394271597266197, -0.20058082044124603,
          -0.08631258457899094, -0.18453843891620636, -0.07810431718826294,
          0.13493742048740387, -0.17528557777404785, 0.02388872392475605,
          0.18938405811786652, 0.1622144728899002, 0.041780829429626465,
          -0.11683150380849838, -0.15375366806983948, -0.09049093723297119,
          0.159606471657753, 0.0634486973285675, -0.019807275384664536,
          -0.13815978169441223,
        ],
        [
          0.1577722579240799, -0.060952555388212204, -0.023440051823854446,
          -0.15610478818416595, -0.20103320479393005, -0.11875386536121368,
          0.06393279880285263, -0.009732084348797798, 0.013823864050209522,
          -0.030825477093458176, 0.12476572394371033, 0.07616683840751648,
          -0.13344180583953857, 0.15798009932041168, -0.04032905772328377,
          -0.07352063804864883, -0.10115214437246323, 0.15454542636871338,
          0.0965428426861763, -0.16435836255550385, -0.17638832330703735,
          -0.03902482986450195, -0.1247209906578064, -0.20767457783222198,
          -0.10953837633132935, -0.02801281400024891, -0.07955914735794067,
          0.17702271044254303, -0.17402124404907227, -0.09906874597072601,
          -0.07571721076965332, 0.029706891626119614, 0.05124348774552345,
          0.030666273087263107, 0.0590171292424202, 0.08638510853052139,
          -0.05782587081193924, 0.11042898148298264, -0.06800736486911774,
          -0.1345757246017456, 0.14675334095954895, 0.007437351159751415,
          0.13474711775779724, 0.037106286734342575, -0.13782009482383728,
          0.036517396569252014, 0.015840020030736923, -0.06139974296092987,
          0.1786794811487198, -0.16762274503707886, 0.1962008774280548,
          0.1811012178659439, 0.0274081788957119, 0.17479921877384186,
          0.05286290496587753, 0.16048051416873932, 0.1373189240694046,
          0.05263517051935196, 0.03778786584734917, -0.09594450145959854,
          0.15662415325641632, -0.1336015909910202, 0.1759931743144989,
          -0.024295171722769737, 0.0798429623246193, -0.11242294311523438,
          -0.018751027062535286, -0.1517317295074463, 0.010232186876237392,
          0.07157237827777863, -0.16761989891529083, 0.12947922945022583,
          -0.018284959718585014, -0.1727188378572464, -0.1298055350780487,
          0.05452200025320053, 0.02329091913998127, -0.19445498287677765,
          0.1425107717514038, 0.11678038537502289, 0.12059192359447479,
          0.015452361665666103, -0.020186452195048332, 0.2552115023136139,
          -0.01235381793230772, -0.023061752319335938, -0.023832401260733604,
          -0.16243045032024384, 0.22486791014671326, -0.10540738701820374,
          -0.07323379069566727, -0.11003933846950531, 0.14980284869670868,
          0.14227500557899475, 0.06732714921236038, 0.19011002779006958,
          -0.04821600392460823, -0.09759436547756195, -0.02985021099448204,
          0.23251885175704956,
        ],
        [
          0.17299528419971466, -0.010365165770053864, -0.004977601580321789,
          0.005432791076600552, -0.17224127054214478, -0.07111698389053345,
          0.013065911829471588, 0.02896573208272457, 0.04280177503824234,
          -0.0174888726323843, 0.0031170856673270464, -0.14309443533420563,
          -0.10968384891748428, -0.012500055134296417, 0.03753150627017021,
          0.12576691806316376, 0.030427364632487297, -0.038464486598968506,
          -0.034055195748806, -0.11060208082199097, 0.00016822274483274668,
          0.13961011171340942, -0.10169488191604614, -0.17426665127277374,
          -0.1050879955291748, 0.09283866733312607, 0.05865148454904556,
          0.10022235661745071, 0.16897419095039368, -0.1687028557062149,
          0.10136519372463226, -0.18108612298965454, 0.17220792174339294,
          0.10121435672044754, 0.03454577550292015, -0.21622507274150848,
          -0.22079786658287048, -0.1258840411901474, -0.06949736177921295,
          -0.1134641095995903, 0.018584927543997765, 0.06368958204984665,
          -0.2407713234424591, -0.05730259045958519, -0.20389029383659363,
          -0.08839116990566254, -0.0053859916515648365, 0.044231902807950974,
          -0.1166319027543068, -0.07866252958774567, 0.12254809588193893,
          -0.08781110495328903, 0.08070282638072968, 0.07278931140899658,
          -0.019739817827939987, -0.014878681860864162, 0.027561098337173462,
          0.01613839529454708, 0.06564589589834213, -0.045135535299777985,
          0.14457055926322937, -0.062888503074646, -0.2112206518650055,
          0.16645708680152893, -0.2302616983652115, -0.11594923585653305,
          -0.20050148665905, -0.004175908397883177, -0.0791221410036087,
          0.12136036157608032, 0.09943610429763794, 0.049265533685684204,
          -0.02449146658182144, -0.13873393833637238, -0.02514662593603134,
          0.03517606481909752, 0.02225395478308201, 0.12839780747890472,
          -0.022624853998422623, -0.019847529008984566, 0.13470987975597382,
          0.12776388227939606, 0.048035964369773865, -0.11419620364904404,
          -0.23678269982337952, 0.08215350657701492, -0.16988444328308105,
          -0.09028477221727371, 0.11754198372364044, -0.14684715867042542,
          -0.19158576428890228, 0.015581898391246796, 0.08155270665884018,
          0.14314396679401398, -0.050970517098903656, -0.11307628452777863,
          -0.03406999632716179, -0.15601691603660583, -0.23374667763710022,
          -0.23271316289901733,
        ],
        [
          -0.03855282440781593, -0.22343632578849792, 0.055899932980537415,
          -0.15905877947807312, 0.018863607197999954, -0.03355216234922409,
          0.11559206992387772, -0.0681033805012703, -0.0845726728439331,
          -0.1545494794845581, -0.026458175852894783, -0.11346744745969772,
          -0.08083761483430862, -0.11230873316526413, -0.09196220338344574,
          0.08972574025392532, 0.10193387418985367, 0.09143146127462387,
          -0.12898194789886475, -0.1287599354982376, -0.07938353717327118,
          -0.14056691527366638, 0.0018959216540679336, -0.18982578814029694,
          -0.028168153017759323, 0.01314692385494709, 0.0159845519810915,
          0.17021726071834564, -0.1994369775056839, -0.0662381574511528,
          -0.09502661228179932, -0.121677927672863, 0.05034729465842247,
          0.08196506649255753, 0.10173710435628891, 0.1349169760942459,
          -0.1617933064699173, -0.007508768234401941, 0.1449933499097824,
          -0.13655702769756317, -0.06530969589948654, -0.03963658586144447,
          0.17136149108409882, -0.17959065735340118, 0.1549014300107956,
          0.12899629771709442, -0.21296964585781097, 0.10396511107683182,
          0.08629932254552841, -0.05986015871167183, 0.004883522167801857,
          0.07343877851963043, -0.1456991732120514, 0.03018341027200222,
          -0.040001675486564636, 0.08710306882858276, -0.14297205209732056,
          0.1666451096534729, -0.13931293785572052, 0.14116328954696655,
          -0.1796092540025711, 0.0995141938328743, -0.1199755147099495,
          -0.1452885866165161, -0.20620635151863098, 0.17211031913757324,
          0.10611610114574432, 0.08360520005226135, 0.0998242124915123,
          -0.015589428134262562, -0.06627163290977478, -0.17310315370559692,
          0.007017472293227911, -0.02462584897875786, -0.04691468924283981,
          0.04036670923233032, 0.03254609555006027, -0.11897648125886917,
          0.1412893682718277, -0.0540621355175972, 0.010705636814236641,
          0.10768330097198486, -0.03026713617146015, 0.09234941750764847,
          -0.03992303088307381, -0.1657438427209854, -0.12505437433719635,
          -0.16940748691558838, 0.13621945679187775, 0.04033838212490082,
          -0.2023143768310547, -0.08591441810131073, -0.09883779287338257,
          -0.07181552052497864, -0.1692737489938736, -0.16733241081237793,
          0.1479618102312088, -0.07244938611984253, 0.12856996059417725,
          0.11856387555599213,
        ],
        [
          0.02970006875693798, -0.12888844311237335, -0.19046789407730103,
          0.059056803584098816, -0.14588120579719543, 0.10419231653213501,
          -0.05343277007341385, -0.2123098075389862, -0.030195871368050575,
          0.1437138468027115, -0.1433550864458084, -0.08914044499397278,
          -0.17285966873168945, -0.08760854601860046, 0.05557304248213768,
          0.005526322405785322, -0.14491724967956543, 0.04064222425222397,
          0.002823923248797655, 0.16367994248867035, -0.12661606073379517,
          -0.04958855360746384, 0.04588589817285538, -0.19240328669548035,
          0.004662171937525272, 0.11126985400915146, 0.09562977403402328,
          0.15188182890415192, 0.15362998843193054, -0.1734062135219574,
          -0.12597736716270447, 0.1795215904712677, 0.17412133514881134,
          -0.06143515557050705, 0.010115598328411579, 0.05923141539096832,
          -0.11637763679027557, -0.006392409559339285, -0.12000416219234467,
          0.028133101761341095, 0.15900148451328278, -0.09049239754676819,
          -0.06541112065315247, -0.1704557240009308, -0.09575072675943375,
          -0.05670766159892082, -0.02661563828587532, -0.045410700142383575,
          -0.10652342438697815, -0.10106276720762253, -0.06935607641935349,
          -0.2129145711660385, 0.06817156076431274, 0.07537978142499924,
          -0.14959651231765747, -0.14453653991222382, 0.16274404525756836,
          0.15043728053569794, 0.09730751812458038, -0.17206639051437378,
          0.137569859623909, 0.09799079596996307, 0.0336974672973156,
          0.01567624881863594, -0.08101103454828262, 0.18737950921058655,
          0.09084407985210419, 0.1491241604089737, -0.008995204232633114,
          0.06894958019256592, -0.12188032269477844, 0.11399008333683014,
          0.05453275889158249, 0.017685776576399803, 0.020132116973400116,
          -0.031065043061971664, 0.1332889199256897, 0.06495868414640427,
          -0.0035538948141038418, -0.13972796499729156, -0.13505561649799347,
          0.1764666587114334, 0.15424107015132904, 0.06901354342699051,
          0.06601835042238235, 0.094012051820755, -0.11291108280420303,
          0.09463348239660263, -0.01419078279286623, 0.17186997830867767,
          -0.08318934589624405, -0.12016090750694275, -0.18755945563316345,
          -0.09473999589681625, -0.027363497763872147, -0.1450246423482895,
          0.09526923298835754, -0.1024487316608429, 0.11932402849197388,
          -0.019984159618616104,
        ],
        [
          -0.163959801197052, -0.13681773841381073, 0.11702727526426315,
          0.052755098789930344, -0.11467818915843964, -0.07684887945652008,
          -0.021921196952462196, 0.02374931238591671, 0.16785946488380432,
          -0.07920573651790619, 0.06075228750705719, -0.1586020588874817,
          0.029457218945026398, -0.1730954498052597, 0.018720906227827072,
          -0.07285036146640778, -0.0022743872832506895, 0.11580756306648254,
          0.1507362425327301, -0.12871617078781128, 0.07248229533433914,
          0.020755304023623466, -0.14279675483703613, 0.12708084285259247,
          -0.15380465984344482, -0.19318319857120514, -0.2192927896976471,
          0.056307584047317505, -0.14695706963539124, 0.12757641077041626,
          -0.19381244480609894, -0.009331083856523037, 0.03849704563617706,
          0.10998205840587616, -0.05416018143296242, 0.08523374050855637,
          -0.23347260057926178, 0.11163654178380966, 0.18510906398296356,
          0.019836358726024628, -0.009245522320270538, 0.010909726843237877,
          -0.07952260971069336, -0.06978102773427963, -0.18318352103233337,
          0.03965064138174057, 0.018478862941265106, -0.02852698788046837,
          0.042209185659885406, -0.08460213989019394, -0.1652759462594986,
          -0.091792993247509, 0.16790828108787537, 0.09666924178600311,
          0.056309208273887634, 0.09545671194791794, -0.09792234003543854,
          0.052215490490198135, 0.06580796092748642, 0.022541873157024384,
          -0.12122152745723724, 0.1138513907790184, 0.1339314579963684,
          -0.19167907536029816, -0.11855731159448624, -0.18073703348636627,
          -0.21178272366523743, -0.18936793506145477, -0.038070935755968094,
          0.1570403128862381, 0.08385370671749115, -0.07172983884811401,
          -0.11301632225513458, 0.053804557770490646, -0.07084181159734726,
          -0.20720531046390533, -0.03258185461163521, -0.08636359125375748,
          -0.08147396892309189, -0.20272991061210632, -0.068883515894413,
          -0.15810051560401917, 0.1335403025150299, -0.1461925059556961,
          -0.03584563359618187, -0.1505865752696991, -0.041483074426651,
          -0.22926756739616394, -0.11974886804819107, -0.04640859365463257,
          -0.17718122899532318, -0.07390133291482925, -0.040796585381031036,
          -0.12810000777244568, 0.1489989459514618, -0.01723727025091648,
          -0.11962539702653885, -0.027531441301107407, -0.07764329016208649,
          -0.16759902238845825,
        ],
      ],
      biases: [
        -0.0054837437346577644, 0.05029093846678734, -0.09801647067070007,
        0.05577274411916733, -0.22975806891918182, 0.02434602938592434,
        -0.14099903404712677, 0.09732206910848618,
      ],
    },
  ],
  inputLookup: null,
  inputLookupLength: 0,
  outputLookup: null,
  outputLookupLength: 0,
  options: {
    inputSize: 0,
    outputSize: 0,
    binaryThresh: 0.5,
    hiddenLayers: [100],
    useGpu: true,
  },
  trainOpts: {
    activation: 'sigmoid',
    iterations: 19,
    errorThresh: 0.005,
    log: true,
    logPeriod: 1,
    leakyReluAlpha: 0.01,
    learningRate: 0.00001,
    momentum: 0.1,
    callbackPeriod: 10,
    timeout: 'Infinity',
    beta1: 0.9,
    beta2: 0.999,
    epsilon: 1e-8,
  },
};