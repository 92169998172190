import { Debug } from './scenes/types';

// TRUE means SHOWN ON MAIN PAGE
export const debugOnMain: Debug = {
  Auto_Restart: false,
  NN_Train_Static: true,
  NN_Train_Evolving: true,
  NN_Reset_Evolving: false,
  Super_Speed: false,
  NN_Help_Screen: false,
  NN_Help_Centerize: false,
  NN_Brand_New: false,
  NN_Help_Pit: false,
  NN_Help_Wall: false,
  Simple_Stage: false,
  Stage: 0, //0123456
  Mode_Infinity: true,
  Dur_Seconds: false,
  Shots: 0,
  Minutes: 1, //01234567
  Music_Active: false,
  Auto_Start: false,
  Ready_Sound_Active: false,
  Use_Camera: false,
  Show_Helper_Keyboard: false,
  Cameras_Visible: false,
  Colliders_P_v_P: false,
  Colliders_P_v_AP: false,
  Colliders_P_v_AE: false,
  Chomp_Velocities: false,
  Colliders_AE_v_AE: false,
  Colliders_AE_v_AP: false,
  Colliders_AB_v_AE: false,
  Colliders_AB_v_AP: false,
  Friction_Air_Active: false,
  AE_Wrap_Screen: false,
  Wall_Jumps_Active: false,
  Default_Damage: false,
  Default_Hitback: false,
  Player_ID_Visible: false,
  Chars_Colored: true,
  Title_Screws: false,
  Health_Inverted: false,
  Matrices_Always: true,
  Console_Log_Buttons: false,
  Console_Log_Connected: false,
  Update_Loops_Skip: 0, // 012
  Load_Time_Extra: false,
  Allow_Koopas: false,
  Allow_BlackChez: false,
  Allow_Chez: false,
  Allow_SlowMo: false,

  Dev_Mode: false,
  Dev_Zoom: 0,
  Char_Override: false,

  Char_Override_ID: 0,
  Bullets_Allow_Groups: false,
  Trophies_On_Shots: false,
  Chomp_Explosion: false,
  Trophies_Always: true,
  Inst_Replay: 0,
  Replay_FastSlow: false,
  Game_Music: 0, // 012
  Flower_On_Init: false,
  Flower_Rapid_Fire: false,
  Flower_Full_Screen: false,
  Flower_Gravity: false,
  Flower_Bounce_Wall: false,
  Flower_1000_Balls: false,
  Flower_ShootRndAmt: 0,
  Flower_HighTrajectory: 0,
};
